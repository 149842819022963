import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

//// Using onChange and onInputChange together break this component's filtering mechanism.
//// For now, we have to decide whether to use handleInputChange & state to clear the TextField
//// automatically, or have the component filter as you type.
//// 
//// 'searchText' only exists to hold the current value of what's being typed, and to clear that value
//// when done - it doesn't affect the mechanism of adding tags or groups to the parent component's state
//// and the corresponding list components.
//// 
//// 'changeCount' exists in this code to be a unique key on the Autocomplete that asks it to re-render
//// whenever it changes. By iterating changeCount when clearSearchBar is called, we're able to reset the 
//// TextField to the default value ("").

export default class AutoCompleteDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            items: props.items,
            // changeCount: 0
        };
    }

    // reason will be either "input" (user input), "reset" (programmatic change), "clear".
    // handleInputChange = (event, value, reason) => {
    //     this.setState({
    //         searchText: value
    //     });
    // };

    // reason will be either "create-option", "select-option", "remove-option", "blur" or "clear".
    handleChange = (event, value, reason) => {
        if (reason === 'select-option' || reason === 'clear') {
            this.props.onItemSelected(event, value);
            this.clearSearchBar();
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            items: nextProps.items
        });
    }

    // This function is not currently working as designed. This is not critical because the field will 
    // clear on blur (when the user clicks elsewhere) or when the text is manually deleted.
    clearSearchBar = () => {
        this.setState({
            searchText: '',
            // changeCount: this.state.changeCount ++,
        });
    };

    render() {
        return (
            <div>
                <Autocomplete
                    // key={this.state.changeCount}
                    value={this.state.searchText}
                    options={this.state.items}
                    clearOnBlur={true}
                    disableClearable
                    filterSelectedOptions={true}
                    // defaultValue={""}
                    // onInputChange={this.handleInputChange}
                    onChange={this.handleChange}
                    renderInput={(params) => 
                        <TextField {...params} label={this.props.title} variant="standard" />
                    }
                />
            </div>
        );
    }
}