import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';

// MATERIAL-UI COMPONENTS
import {
    IconButton,
    InputAdornment
  } from '@material-ui/core';
  
// MATERIAL ICONS
import {
    DateRange as DateRangeIcon
  } from '@material-ui/icons';

import '../../App.css';

export default function DateTimePickerDialog(props) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
                label={props.label}
                inputVariant="outlined"
                value={props.date}
                onChange={(e) => props.setDate(e)}
                strictCompareDates
                hideTabs
                variant="dialog"
                disablePast
                format="MM/dd/yyyy hh:mm a"
                minDate={props.minDate}
                minDateMessage="Invalid selection"
                minutesStep={15}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton>
                        <DateRangeIcon />
                        </IconButton>
                    </InputAdornment>
                    )
                }}
            />
        </MuiPickersUtilsProvider>
    )
}