import React from 'react';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@material-ui/core'


function MenuItem(props) {
    return (
        <Tooltip arrow enterDelay={1000} placement='right' title={props.security ? '' : `You don't have permission to view this page.`} >
            <span>
                <ListItem
                    button
                    component="a"
                    disabled={!props.security || props.path === props.link} href={props.link}
                    style={props.nested && { paddingLeft: '50px' }}
                >
                    <ListItemIcon>{props.icon}</ListItemIcon>
                    <ListItemText primary={props.label} />
                </ListItem>
            </span>
        </Tooltip>
    );
}

export default MenuItem;
