import { DialogActions, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';
import { promoteMenuIcon } from '../../apiService.js';
import '../../App.css';
import { getEnvironments } from '../../utils';
export default class PromoteMenuIconComponent extends React.Component {

    constructor(props) {
        super(props);
        this.sourceEnvironment = getEnvironments().sourceEnvironment;
        this.destinationEnvironment = getEnvironments().destinationEnvironment;
        this.state = {
            menuSections: [],
            menuItems: [],
            open: false,
            imageName: '',
            popup: false,
            popupMessage: ''
        };
        this.getMenuSections = this.getMenuSections.bind(this);
        this.getMenuItems = this.getMenuItems.bind(this);
        this.menuSectionSelection = this.menuSectionSelection.bind(this);
        this.menuItemSelection = this.menuItemSelection.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSuccessMessageClose = this.handleSuccessMessageClose.bind(this);
        this.promoteImages = this.promoteImages.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.getEnvironmentUrl = this.getEnvironmentUrl.bind(this);
    }

    componentWillMount() {
        let menuSections = this.getMenuSections();
        this.setState({
            menuSections
        });
    }

    menuSectionSelection(event, index, value) {
        this.setState({
            menuSection: event.target.value,
            menuItems: this.getMenuItems(event.target.value)
        });
    }

    menuItemSelection(event, index, value) {
        this.setState({
            menuItem: event.target.value,
            imageName: event.target.value.replace(/ /g, '_') + '.png'
        });
    }
    handleRequestClose = () => {
        this.setState({
            popup: false,
        });
    };

    async promoteImages() {
        let result = await this.callLambda();
        this.setState({
            open: false
        })
        console.log(result);
    }

    callLambda() {
        return new Promise((resolve, reject) => {
            let body = {
                "model": this.state.imageName
            }

            promoteMenuIcon(body)
                .then(response => {
                    if (response.data) {
                        this.setState({
                            popup: true,
                            popupMessage: response.data.toString(),
                            creatingArticle: false
                        });
                        resolve("Failed promoting images.");
                    }
                    else {
                        this.setState({
                            popup: true,
                            popupMessage: 'Images promoted to ' + this.destinationEnvironment + ' successfully!',
                        });
                        resolve("Success promoting images.");
                    }
                })
                .catch(error => {
                    console.log("Error: " + error.toString());
                    this.setState({
                        popup: true,
                        popupMessage: error.toString(),
                    });
                    resolve("Failed promoting images: " + error.toString())
                })
        });
    }

    getMenuSections() {
        return [
            "Cars & Minivans",
            "Crossover & SUVs",
            "Trucks",
            "Non-Production",
            "Electrified",
        ];
    }

    getMenuItems(section) {
        switch (section) {
            case "Cars & Minivans":
                return [
                    "86",
                    "Avalon",
                    "Camry",
                    "Corolla",
                    "GR Corolla",
                    "Corolla Hatchback",
                    "GR Supra",
                    "Mirai",
                    "Prius",
                    "Prius Prime",
                    "Sienna",
                    "Toyota Crown"
                ];
            case "Crossover & SUVs":
                return [
                    "4Runner",
                    "bZ4X",
                    "C-HR",
                    "Corolla Cross",
                    "Grand Highlander",
                    "Highlander",
                    "RAV4",
                    "RAV4 Prime",
                    "Sequoia",
                    "Venza",
                    "Toyota Crown Signia"
                ];
            case "Trucks":
                return [
                    "Tacoma",
                    "Tundra",
                ];
            case "Non-Production":
                return [
                    "Corolla iM",
                    "Prius c",
                    "Prius v",
                    "Prius Plug-In Hybrid",
                    "Yaris",
                    "Yaris Sedan",
                    "Yaris iA",
                    "Scion FR-S",
                    "Scion iA",
                    "Scion iM",
                    "Scion iQ",
                    "Scion tC",
                    "Scion xB",
                    "Scion xD",
                    "Land Cruiser"
                ];
            case "Electrified":
                return [
                    "Avalon Hybrid",
                    "Camry Hybrid",
                    "Corolla Hybrid",
                    "Highlander Hybrid",
                    "RAV4 Hybrid",
                    "Sienna Hybrid",
                    "Tundra Hybrid"
                ];
            default:
                break;
        }
    }


    handleClose = () => {
        this.setState({ open: false });
    };

    handleSuccessMessageClose = () => {
        this.setState({ uploadComplete: false });
        window.location.reload();
    };

    getEnvironmentUrl = (srcEnv) => (srcEnv === "dev" ? srcEnv + ".nonprod" : srcEnv);

    render() {

        const actions1 = [
            <Button
                color='primary'
                onClick={this.handleClose}
            >Cancel</Button>,
            <Button
                color='primary'
                keyboardFocused={true}
                onClick={this.promoteImages}
            >Upload</Button>
        ];

        return (
            <div>
                <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginTop: "30px" }}>
                    <p>Select Menu Section:</p>
                    <Select value={this.state.menuSection} onChange={this.menuSectionSelection} >
                        {this.state.menuSections.map(menuSection => {
                            return <MenuItem key={menuSection} value={menuSection}>{menuSection}</MenuItem>
                        })}
                    </Select>
                </div>
                {this.state.menuSection ?
                    <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginTop: "30px" }}>
                        <p>Select Menu Item:</p>
                        <Select value={this.state.menuItem} onChange={this.menuItemSelection} >
                            {this.state.menuItems.map(menuItem => {
                                return <MenuItem key={menuItem} value={menuItem}>{menuItem}</MenuItem>
                            })}
                        </Select>
                    </div>
                    : null}
                <div style={{ marginTop: "20px" }}>
                    {(this.state.menuSection && this.state.menuItem) ?
                        <div>
                            <div className='Menu-Display'>
                                <div className='Image-Title'><h2>Default</h2></div>
                                <img className='Menu-Image'
                                    src={`https://${this.getEnvironmentUrl(this.sourceEnvironment)}.engage.toyota.com/static/images/jellybeans/profile/${this.state.imageName}`}
                                />
                            </div>
                            <div className='Menu-Display'>
                                <div className='Image-Title'><h2>Hover</h2></div>
                                <img className='Menu-Image'
                                    src={`https://${this.getEnvironmentUrl(this.sourceEnvironment)}.engage.toyota.com/static/images/jellybeans/front/${this.state.imageName}`}
                                />
                            </div>
                            <div className='Menu-Button'>
                                <Button variant='contained' color='secondary' onClick={() => { this.setState({ open: true }) }} >Promote Images</Button>
                            </div>
                        </div> : null}
                </div>
                <Dialog
                    open={this.state.open}
                    onRequestClose={this.handleClose}>
                    <DialogContent>
                        {"Are you sure you want to promote these images to " + this.destinationEnvironment + "?"}
                    </DialogContent>
                    <DialogActions>
                        {actions1}
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={this.state.popup}
                    message={this.state.popupMessage}
                    autoHideDuration={6000}
                    onRequestClose={this.handleRequestClose}
                />
            </div>
        );
    }
}

