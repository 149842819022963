/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import { MsalProvider} from "@azure/msal-react";
import { msalInstance } from './auth';
  
  const AppProvider = () => (
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
  );
  
  ReactDOM.render(<AppProvider />, document.getElementById('root'));
  registerServiceWorker();
  unregister();
