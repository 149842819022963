import React, { useEffect, useState } from 'react';
import { logout } from '../../auth';
import '../../App.css';
import Countdown from 'react-countdown';

// MATERIAL-UI V4
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import { Alert } from '@material-ui/lab';

// MATERIAL-UI ICONS V4
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';
import MenuIcon from '@material-ui/icons/Menu';


function AppToolbar(props) {
  const [atTop, setAtTop] = useState(true);
  const [timerSeverity, setTimerSeverity] = useState("warning");
  const [timerAtZero, setTimerAtZero] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', setToolbarElevation);

    return () => 
      window.removeEventListener('scroll', setToolbarElevation);
  }, []);

  function setToolbarElevation() {
    if (window.scrollY === 0) {
      setAtTop(true);
    } else {
      setAtTop(false);
    }
  }

  function logoutClick() {
    logout();
  }

  function helpClick() {
    window.open('https://confluence.sdlc.toyota.com/display/ES/Mission+Control+User+Guide', '_blank');
  }

  return (
    <AppBar position='sticky' color='inherit' elevation={atTop ? 0 : 2} >
      <Toolbar>
        {!props.persistentMenu &&
          <IconButton onClick={() => props.setDrawerOpen(!props.drawerOpen)} >
            <MenuIcon />
          </IconButton>
        }

        {/* Page Title & Buttons for Tablet & Desktop */}
        <Hidden xsDown >
          <Typography noWrap style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '66px', fontWeight: 'bold' }} variant='h5'>
            {props.pageTitle}
          </Typography>
          {props.showCountdown ? 
            <Alert severity={timerAtZero ? "error" : "warning"} onClick={() => props.showModalFunction(timerAtZero)} className="toolbarCountdown">{
              <Countdown 
                date={new Date(props.expirationTime)} 
                renderer={props.countdownRenderer} 
                key={Date.now()}
                onComplete={() => {setTimerAtZero(true)}}
              />}
            </Alert> : null}
          <Button
            size='small'
            onClick={helpClick}
            startIcon={<InfoIcon />}
            style={{width: "100px"}}
          >Help</Button>
          <Button
            size='small'
            onClick={logoutClick}
            startIcon={<ExitToAppIcon />}
            style={{width: "100px"}}
          >Logout</Button>
        </Hidden>

        {/* Page Title & Buttons for Mobile */}
        <Hidden smUp >
          <Typography noWrap style={{ flexGrow: 1, textAlign: 'left', fontWeight: 'bold' }} >
            {props.pageTitle}
          </Typography>
          <IconButton onClick={helpClick} ><InfoIcon /></IconButton>
          <IconButton onClick={logoutClick} ><ExitToAppIcon /></IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

export default AppToolbar;