import React from 'react';
import GlossaryImageGrid from './glossaryImageGrid';
import { v4 as uuidV4 } from 'uuid';
import {
    Card,
    Grid,
    Tooltip,
    Button,
    CircularProgress,
} from '@material-ui/core';
import {
    DesktopWindows as ProdIcon,
    Visibility as StagingIcon,
    Edit as EditIcon,
    Publish as PromoteIcon,
    Delete as ArchiveIcon,
    RestoreFromTrash as RestoreIcon,
    GetApp as UnpromoteIcon,
} from '@material-ui/icons';
import config from '../../config';
import { applyTrademarkSymbols } from '../../utils';
import './glossary.css';


const GlossaryTermView = (props) => {
    const termObject = {
        id: props.term.id,
        stagingId: props.term.stagingId || props.term.id,
        term: props.term.term || "",
        category: props.term.category || "",
        status: props.term.status || "",
        description: props.term.description || "",
        disclaimer: props.term.disclaimer || "",
        notes: props.term.notes || "",
        images: props.term.images || [],
        relatedContent: props.term.relatedContent || [{ name: "", url: "" }, { name: "", url: "" }, { name: "", url: "" }],
        featureAvailability: props.term.featureAvailability || "",
        isArchived: props.term.isArchived,
        isPromoted: props.term.isPromoted,
    };

    const archiveTerm = () => props.archiveTerm(termObject.id);
    const promoteTerm = () => {
        const upperEnvTerm = {
            id: uuidV4(),
            stagingId: termObject.id,
            term: termObject.term,
            category: termObject.category,
            description: termObject.description,
            disclaimer: termObject.disclaimer,
            notes: termObject.notes,
            images: termObject.images,
            relatedContent: termObject.relatedContent,
            featureAvailability: termObject.featureAvailability,
            isArchived: termObject.isArchived,
        };
        props.promoteTerm(upperEnvTerm);
    };
    const unpromoteTerm = () => props.unpromoteTerm(termObject.id);
    const restoreTerm = () => props.restoreTerm(termObject.id);

    function formatUrl(url) {
        let urlRoot = window && window.location && window.location.origin;
        if (urlRoot.includes('localhost:')) urlRoot = 'http://dev.mc.nonprod.engage.toyota.com';
        let termEnv;
        switch (termObject.status) {
            case 'lower':
                termEnv = urlRoot.includes('dev' || 'test') ? 'dev.' : 'staging.';
                break;
            case 'upper':
                termEnv = urlRoot.includes('dev' || 'test') ? 'test.' : '';
                break;
            default:
                termEnv = '';
        }
        urlRoot = urlRoot.replace('dev.mc.', termEnv);
        urlRoot = urlRoot.replace('test.mc.', termEnv);
        urlRoot = urlRoot.replace('staging.mc.', termEnv);
        urlRoot = urlRoot.replace('mc.', termEnv);

        if (url.substring(0, 1) === '/') {
            return `${urlRoot}${url}`;
        }
        if (url.substring(0, 7) !== 'http://' && url.substring(0, 8) !== 'https://') {
            return `http://${url}`;
        }
        return url;
    }

    function renderStatus() {
        switch (props.term.status) {
            case 'upper':
                return (<>
                    <ProdIcon />
                    <div>{config.environments.destinationEnvironment.toUpperCase()}</div>
                </>);
            case 'lower':
                return (<>
                    <StagingIcon />
                    <div>{config.environments.sourceEnvironment.toUpperCase()}</div>
                </>);
            case 'archive':
                return (<>
                    <ArchiveIcon />
                    <div>archived</div>
                </>);
            default: return;
        }
    }

    function renderButtons() {
        switch (props.term.status) {
            case 'upper':
                return (<>
                    <Tooltip arrow placement="left" title={`Remove Term from ${config.environments.destinationEnvironment.toUpperCase()}`}>
                        <Button
                            startIcon={props.unpromoting ? <CircularProgress size="20px" color="inherit" /> : <UnpromoteIcon />}
                            className="button"
                            variant="contained"
                            style={{ backgroundColor: "#EB0A1E", color: "#fff" }} 
                            onClick={props.unpromoting ? undefined : unpromoteTerm}
                            disabled={props.unpromoting}
                        > {props.unpromoting ? 'Deleting' : 'Delete'} </Button>
                    </Tooltip>
                </>)
            case 'lower':
                return (<>
                    <Tooltip arrow placement="left" title="Make Changes to Term">
                        <Button
                            startIcon={<EditIcon />}
                            className="button"
                            variant="contained"
                            color="primary"
                            onClick={props.editTerm}
                        > Edit </Button>
                    </Tooltip>
                    {(props.term.isPromoted || props.loading) ?
                        <Button
                            startIcon={props.promoting ? <CircularProgress size="20px" color="inherit" /> : <PromoteIcon />}
                            className="button"
                            variant="contained"
                            color="primary"
                            disabled
                        > Promote </Button>
                        :
                        <Tooltip arrow placement="left" title={`Publish Changes from ${config.environments.sourceEnvironment.toUpperCase()} to ${config.environments.destinationEnvironment.toUpperCase()}`}>
                            <Button
                                startIcon={props.promoting ? <CircularProgress size="20px" color="inherit" /> : <PromoteIcon />}
                                className="button"
                                variant="contained"
                                style={{ backgroundColor: "#7D4285", color: "#fff" }}
                                onClick={props.promoting ? undefined : promoteTerm}
                                disabled={props.promoting}
                            > {props.promoting ? 'Promoting' : 'Promote'} </Button>
                        </Tooltip>
                    }
                    <Tooltip arrow placement="left" title={`Remove Term from ${config.environments.sourceEnvironment.toUpperCase()}`}>
                        <Button
                            startIcon={props.archiving ? <CircularProgress size="20px" color="inherit" /> : <ArchiveIcon />}
                            className="button"
                            variant="contained"
                            style={{ backgroundColor: "#EB0A1E", color: "#fff" }} 
                            onClick={props.archiving ? undefined : archiveTerm}
                            disabled={props.archiving}
                        > {props.archiving ? 'Archiving' : 'Archive'} </Button>
                    </Tooltip>
                </>)
            case 'archive':
                return (<>
                    <Tooltip arrow placement="left" title={`Restore Term to ${config.environments.sourceEnvironment.toUpperCase()}`}>
                        <Button
                            startIcon={props.restoring ? <CircularProgress size="20px" color="inherit" /> : <RestoreIcon />}
                            className="button"
                            variant="contained"
                            style={{ backgroundColor: "#F8D76B", color: "#58595B" }} 
                            onClick={props.restoring ? undefined : restoreTerm}
                            disabled={props.restoring}
                        > {props.restoring ? 'Restoring' : 'Restore'} </Button>
                    </Tooltip>
                </>)
            default: return;
        }
    }

    function hasRelatedContent() {
        if (!props.term.relatedContent) {
            return false;
        }
        if (props.term.relatedContent[0].name !== "" ||
            props.term.relatedContent[0].url !== "" ||
            props.term.relatedContent[1].name !== "" ||
            props.term.relatedContent[1].url !== "" ||
            props.term.relatedContent[2].name !== "" ||
            props.term.relatedContent[2].url !== "") {
            return true;
        }
        return false;
    }

    return (
        <Card className="glossary-view card" variant="outlined">
            <Grid container spacing={3}>
                <Grid item md={1} className="column status">
                    {renderStatus()}
                </Grid>
                <Grid item md={2} className="column info">
                    <div className="term">{applyTrademarkSymbols(props.term.term)}</div>
                    <div className="category">{props.term.category}</div>
                    <br />
                    <div className="details"><strong>ID: </strong>{props.term.id}</div>
                    {/* If term is in upper environment, show stagingId */}
                    {props.term.status === "upper" ?
                        <div className="details"><strong>Staging ID: </strong>{props.term.stagingId}</div>
                        : <></>}
                    {/* If term is archived, show archivedAt date/time */}
                    {props.term.archivedAt ?
                        <div className="details">Archived {props.term.archivedAt}</div>
                        : <></>}
                    {/* If term is in upper environment, show createdAt date/time */}
                    {props.term.createdAt ?
                        <div className="details">Published {props.term.createdAt}</div>
                        : <></>}
                </Grid>
                <Grid item md={4} className="column content">
                    <div className="description">{applyTrademarkSymbols(props.term.description)}</div>

                    {/* If any related content exists, show */}
                    {!hasRelatedContent() ? <></> :
                        <div className="relatedContent">
                            <br />
                            <div className="header">Related Content</div>
                            {props.term.relatedContent?.map(content => {
                                if (content.name || content.url) return (
                                    <React.Fragment key={content.name}>
                                        <Tooltip arrow placement="top" title={formatUrl(content.url)}>
                                            <a href={formatUrl(content.url)} target="_blank" key={content.url} >{content.name}</a>
                                        </Tooltip>
                                        <br />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    }

                    {/* If a feature availability link exists, show */}
                    {!props.term.featureAvailability ? <></> :
                        <>
                            <br />
                            <Tooltip arrow placement="top" title={formatUrl(props.term.featureAvailability)}>
                                <a className="featureAvailability" href={formatUrl(props.term.featureAvailability)} target="_blank" >See Feature Availability</a>
                            </Tooltip>
                        </>
                    }

                    {/* If disclaimer exists, show in lower environment and archive view */}
                    {!props.term.disclaimer ? <></> :
                        <div className="disclaimer">
                            <br />
                            <strong>Disclaimer: </strong>
                            {applyTrademarkSymbols(props.term.disclaimer)}
                        </div>
                    }

                    {/* If notes exists, show in lower environment and archive view */}
                    {props.term.status === 'upper' || !props.term.notes ? <></> :
                        <>
                            <hr />
                            <div className="header">Content Manager Notes:</div>
                            <div className="notes">{props.term.notes}</div>
                        </>
                    }
                </Grid>
                <Grid item md={3} className="column images">
                    <GlossaryImageGrid
                        env={props.term.status}
                        images={props.term.images}
                        folder={props.term.stagingId}
                        editing={false}
                    />
                </Grid>
                <Grid item md={2} className="column actions">
                    {renderButtons()}
                </Grid>
            </Grid>
        </Card >
    );
}

export default GlossaryTermView;