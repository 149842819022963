import React from 'react';
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ButtonGroup,
    Button,
    Hidden,
    CircularProgress,
} from '@material-ui/core';
import {
    LibraryAdd as CreateIcon,
    DesktopWindows as ProdIcon,
    Visibility as StagingIcon,
    Delete as ArchiveIcon,
    Refresh,
} from '@material-ui/icons';
import config from '../../config';

const GlossaryToolbar = (props) => {
    function handleDropdown(event) {
        props.setFilter({ ...props.filter, category: event.target.value });
    }

    function handleText(event) {
        props.setFilter({ ...props.filter, text: event.target.value });
    }

    function handleCreateButton() {
        props.setCreateMode(true);
    }

    const showUpperTerms = () => props.setFilter({ ...props.filter, tabView: 'upper' });
    const showLowerTerms = () => props.setFilter({ ...props.filter, tabView: 'lower' });
    const showArchive = () => props.setFilter({ ...props.filter, tabView: 'archive' });

    return (<>
        <Grid
            className="glossary-toolbar"
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            container
            spacing={1}
        >
            <Grid item xs={6} md={3}>
                <TextField
                    fullWidth
                    variant="outlined"
                    className="search-input"
                    value={props.filter.text}
                    onChange={handleText}
                    onSubmit={(e) => e.preventDefault}
                    label="Search"
                    type="search"
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <FormControl fullWidth className="select-input" variant="outlined" >
                    <InputLabel id="category-dropdown">Filter by Category</InputLabel>
                    <Select
                        label="Filter by Category"
                        labelId='category-dropdown'
                        value={props.filter.category}
                        onChange={handleDropdown}
                    >
                        <MenuItem value=""><em>All Terms (A-Z)</em></MenuItem>
                        {props.categoriesInUse.map(category => {
                            return <MenuItem key={category} value={category}>{category}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={5} md={2}>
                {props.createMode || props.filter.tabView !== 'lower' ? <></> :
                    <Button startIcon={<CreateIcon />} onClick={handleCreateButton} >
                        <Hidden xsDown>Create</Hidden>
                    </Button>
                }
                <Button startIcon={<Refresh />} onClick={props.refreshTerms} >
                    <Hidden xsDown>Refresh</Hidden>
                </Button>
            </Grid>
            <Grid item xs={7} md={4}>
                <ButtonGroup size="small" fullWidth variant="contained">
                    {props.filter.tabView === 'upper' ?
                        <Button id='upper' style={{ backgroundColor: "#7D4285", color: "#fff" }} onClick={showUpperTerms}>
                            {props.loadingProd ? <CircularProgress size="20px" color="inherit" /> : <ProdIcon />}
                            <Hidden xsDown ><span className="button-label" >{config.environments.destinationEnvironment}</span></Hidden>
                        </Button> :
                        <Button id='upper' onClick={showUpperTerms}>
                            {props.loadingProd ? <CircularProgress size="20px" color="inherit" /> : <ProdIcon />}
                            <Hidden xsDown ><span className="button-label" >{config.environments.destinationEnvironment}</span></Hidden>
                        </Button>}
                    {props.filter.tabView === 'lower' ?
                        <Button id='lower' style={{ backgroundColor: "#F8D76B", color: "#58595B" }} onClick={showLowerTerms}>
                            {props.loadingStaging ? <CircularProgress size="20px" color="inherit" /> : <StagingIcon />}
                            <Hidden xsDown ><span className="button-label" >{config.environments.sourceEnvironment}</span></Hidden>
                        </Button> :
                        <Button id='lower' onClick={showLowerTerms}>
                            {props.loadingStaging ? <CircularProgress size="20px" color="inherit" /> : <StagingIcon />}
                            <Hidden xsDown ><span className="button-label" >{config.environments.sourceEnvironment}</span></Hidden>
                        </Button>}
                    {props.filter.tabView === 'archive' ?
                        <Button id='archive' style={{ backgroundColor: "#EB0A1E", color: "#fff" }} onClick={showArchive}>
                            {props.loadingArchive ? <CircularProgress size="20px" color="inherit" /> : <ArchiveIcon />}
                            <Hidden xsDown ><span className="button-label" >archive</span></Hidden>
                        </Button> :
                        <Button id='archive' onClick={showArchive}>
                            {props.loadingArchive ? <CircularProgress size="20px" color="inherit" /> : <ArchiveIcon />}
                            <Hidden xsDown ><span className="button-label" >archive</span></Hidden>
                        </Button>}
                </ButtonGroup>
            </Grid>
        </Grid>
    </>);
}

export default GlossaryToolbar;
