import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';

class NotificationDialog extends React.Component {

  state = {
    open: false,
    message: "Default Message"
  };

  handleOpen = (message) => {
    this.setState({ open: true, message: message });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
        >
          <DialogContent>
            {this.state.message}
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={this.handleClose} >Ok</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default NotificationDialog;