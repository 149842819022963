import React, { useEffect, useState } from 'react';
import GlossaryTermView from './glossaryTermView';
import GlossaryTermEdit from './glossaryTermEdit';


const GlossaryTerm = (props) => {
    const [editMode, setEditMode] = useState();
    const [saving, setSaving] = useState(false);
    const [archiving, setArchiving] = useState(false);
    const [promoting, setPromoting] = useState(false);
    const [unpromoting, setUnpromoting] = useState(false);
    const [restoring, setRestoring] = useState(false);
    const [hidden, setHidden] = useState();

    useEffect(() => {
        setEditMode(props.editMode);
    }, []);

    const editTerm = () => setEditMode(true);

    const discardChanges = () => setEditMode(false);

    async function saveTerm(body) {
        setSaving(true);
        props.updateTermInStaging(body.id, body, () => {
            setSaving(false);
            setEditMode(false);
            termProps.term.isPromoted = false;
        });
    }

    async function archiveTerm(id) {
        setArchiving(true);
        props.removeFromStaging(id, () => {
            setHidden(true);
            setArchiving(false);
        });
    }
    
    async function promoteTerm(body) {
        setPromoting(true);
        props.publishToProd(body, () => {
            setPromoting(false);
            termProps.term.isPromoted = true;
        });
    }

    async function unpromoteTerm(id) {
        setUnpromoting(true);
        props.removeFromProd(id, () => {
            setHidden(true);
            setUnpromoting(false);
        });
    }

    async function restoreTerm(id) {
        setRestoring(true);
        props.restoreTermToStaging(id, () => {
            setHidden(true);
            setRestoring(false);
        });
    }

    const termProps = {
        term: props.term,
        menuCategories: props.menuCategories,
        loading: props.loading,
        editTerm: editTerm,
        saveTerm: saveTerm,
        archiveTerm: archiveTerm,
        discardChanges: discardChanges,
        promoteTerm: promoteTerm,
        unpromoteTerm: unpromoteTerm,
        restoreTerm: restoreTerm,
        uploadImage: props.uploadImage,
        saving: saving,
        archiving: archiving,
        promoting: promoting,
        unpromoting: unpromoting,
        restoring: restoring,
    }

    return <>
        {hidden ? <></> : <>
            {editMode ?
                <GlossaryTermEdit {...termProps} />
                :
                <GlossaryTermView {...termProps} />
            }
        </>}
    </>;
}

export default GlossaryTerm;