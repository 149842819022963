import React from 'react';
import {
    Chip
} from '@material-ui/core';
import {
    Check as CheckIcon,
} from '@material-ui/icons';
import config from '../../config';

function CreatedChip(props) {
    const formattedDate = props.date && Intl.DateTimeFormat("en-us", { month: "2-digit", day: "2-digit", year: "2-digit" }).format(Date.parse(props.date));

    return (
        <Chip
            icon={<CheckIcon />}
            label={typeof formattedDate === 'string' ? formattedDate : config.environments.formattedSourceEnvironment}
            size="small"
            style={ props.expired ? {backgroundColor: "#e0e0e0", color: "#616161", width: '100%'} : { backgroundColor: '#F8D76B', color: '#58595B', width: '100%' }}
        />
    );
}

export default CreatedChip;
