import React from 'react';
import PropTypes from 'prop-types';
import '../../App.css';
import DropDown from '../../components/DropDownMenu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
export default function TagTypeSelection(props) {
    return (
        <React.Fragment>
            <h1 className="App-title">{props.title ? props.title : 'Select Tags'}</h1>
            <div style={{ display: "flex", alignitems: "center", justifyContent: "center", marginBottom: "20px", marginTop: "20px" }}>
                <p>Tag Type:</p>
                <Select value={props.selectedValue} onChange={props.handleSelection} >
                    {props.tagOptions.map(tagOption => {
                        return <MenuItem key={tagOption} value={tagOption}>{tagOption}</MenuItem>
                    })}
                </Select>
            </div>
        </React.Fragment>
    );
}

TagTypeSelection.propTypes = {
    title: PropTypes.string,
    tagOptions: PropTypes.array.isRequired,
    handleSelection: PropTypes.func.isRequired,
    selectedValue: PropTypes.string
}