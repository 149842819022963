import React from 'react';
import '../../App.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import { getTags, postTag } from '../../apiService';
import ListEditor from '../../containers/EditTags/listEditor';
import TagTypeSelection from '../EditTags/tagTypeSelection';
import NotificationDialog from '../../components/NotificationDialog';
import DialogPopup from '../../components/Dialog';
import AutoCompleteDropDown from '../../components/AutoCompleteDropDown';

export default class EditTagGroups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCreateDialogOpen: false,
            isDeleteDialogOpen: false,
            tagGroupListNames: [], // array of String names of tag groups
            tagGroupList: [],
            selectedTagGroup: null, // object with selected group name, subject tags, and vehicle tags
            subjectTagList: [],
            uploadInProgress: true,
            vehicleTagList: [],
        }
    }

    getTagFile = (listType) => {
        return new Promise((resolve, reject) => {
            try {
                getTags(listType)
                    .then(response => {
                        return resolve(response.data);
                    })
            } catch (ex) {
                return reject(ex);
            }
        })
    }

    getAllTagData = () => {
        try {
            Promise.all([this.getTagFile('subject'), this.getTagFile('vehicle'), this.getTagFile('group')])
                .then(response => {
                    const groupNames = response[2].map(group => group.groupName);
                    this.setState({
                        subjectTagList: response[0],
                        vehicleTagList: response[1],
                        tagGroupList: response[2],
                        tagGroupListNames: groupNames,
                        uploadInProgress: false,
                    });
                });
        }
        catch (ex) {
            this.notificationDialog.handleOpen(`There was an issue loading tag groups. Please try again or contact the dev team.`);
            console.log(ex);
        }
    }

    componentDidMount = () => {
        this.getAllTagData();
    }

    updateSelectedTagGroup = (event) => {
        const selectedGroup = this.getSelectedGroupData(event.target.value);
        this.setState({
            selectedTagGroup: selectedGroup,
        });

    }

    groupExists = (name, list) => {
        return (!!list.find(item => item.groupName === name));
    }

    createNewListGroup = (groupName) => {
        const existingListGroups = [...this.state.tagGroupList];

        if (!this.groupExists(groupName, existingListGroups)) {
            this.createNewTagGroup(groupName);
        }
        else {
            const selectedGroup = this.getSelectedGroupData(groupName);
            this.setState({
                selectedTagGroup: selectedGroup,
            });
        }
    }

    itemExists = (name, items) => {
        if (items.includes(name)) {
            return true;
        }
        return false;
    }

    createNewSubjectTag = (name) => {
        this.createTag(name, this.state.subjectTagList, 'subject');
    }

    createNewVehicleTag = (name) => {
        this.createTag(name, this.state.vehicleTagList, 'vehicle');
    }

    createTag = (newTagName, currentTagList, tagType) => {
        try {
            if (!this.itemExists(newTagName, currentTagList)) {
                if (tagType === 'vehicle') {
                    const updatedVehicleTags = [...currentTagList, newTagName];
                    this.setState({
                        vehicleTagList: updatedVehicleTags,
                    });
                }
                else {
                    this.setState({
                        subjectTagList: [...currentTagList, newTagName]
                    });
                }
                this.addTagToExistingGroup(this.state.selectedTagGroup.groupName, newTagName, tagType);
            }
            else {
                this.notificationDialog.handleOpen(`The tag '${newTagName}' already exists. Please choose it from the list of available tags to add it to the group.`);
            }
        }
        catch (ex) {
            console.log(JSON.stringify(ex));
            this.notificationDialog.handleOpen(`Error creating a tag for ${newTagName}: ${JSON.stringify(ex)}`);
        }
    }

    addSubjectTag = (event, tagName) => {
        this.addTagToExistingGroup(this.state.selectedTagGroup.groupName, tagName, 'subject');
    }

    addVehicleTag = (event, tagName) => {
        this.addTagToExistingGroup(this.state.selectedTagGroup.groupName, tagName, 'vehicle');
    }

    getSelectedGroupData = (groupName) => {
            return groupName && this.state.tagGroupList.find(group => group.groupName.trim() === groupName.trim());
    }

    addTagToExistingGroup = (groupName, tagName, tagType) => {
        const selectedGroup = this.getSelectedGroupData(groupName);
        const groupSubjectTags = [...selectedGroup.subjectTags];
        const groupVehicleTags = [...selectedGroup.vehicleTags];
        if (!this.itemExists(tagName, groupSubjectTags) && !this.itemExists(tagName, groupVehicleTags)) {
            const updatedGroup = {
                'groupName': groupName,
                'subjectTags': tagType === 'subject' ? groupSubjectTags.concat(tagName) : groupSubjectTags,
                'vehicleTags': tagType === 'vehicle' ? groupVehicleTags.concat(tagName) : groupVehicleTags,
            };

            const tagGroupCopy = this.state.tagGroupList;
            const groupPosition = tagGroupCopy.findIndex(group => group.groupName === groupName);
            tagGroupCopy.splice(groupPosition, 1, updatedGroup);

            this.setState({
                tagGroupList: tagGroupCopy,
                selectedTagGroup: updatedGroup,
            });
        }
        else {
            this.notificationDialog.handleOpen(`The tag '${tagName}' already exists in this group.`);
        }
    }

    removeSubjectTag = (tagName) => {
        this.removeTagFromExistingGroup(this.state.selectedTagGroup.groupName, tagName, 'subject');
    }

    removeVehicleTag = (tagName) => {
        this.removeTagFromExistingGroup(this.state.selectedTagGroup.groupName, tagName, 'vehicle');
    }

    removeTagFromExistingGroup = (groupName, tagName, tagType) => {
        const selectedGroup = this.getSelectedGroupData(groupName);
        let newTagList;
        let updatedGroup = selectedGroup;
        if (this.itemExists(tagName, selectedGroup.vehicleTags) || this.itemExists(tagName, selectedGroup.subjectTags)) {
            if (tagType === 'vehicle') {
                newTagList = selectedGroup.vehicleTags.filter(tag => tag.toLowerCase() !== tagName.toLowerCase());
                updatedGroup = {
                    'groupName': groupName,
                    'vehicleTags': newTagList,
                    'subjectTags': selectedGroup.subjectTags,
                }
            }
            else {
                newTagList = selectedGroup.subjectTags.filter(tag => tag.toLowerCase() !== tagName.toLowerCase());
                updatedGroup = {
                    'groupName': groupName,
                    'vehicleTags': selectedGroup.vehicleTags,
                    'subjectTags': newTagList,
                }
            }

            const tagGroupCopy = this.state.tagGroupList;
            const groupPosition = tagGroupCopy.findIndex(group => group.groupName === groupName);
            tagGroupCopy.splice(groupPosition, 1, updatedGroup);

            this.setState({
                tagGroupList: tagGroupCopy,
                selectedTagGroup: updatedGroup,
            });
        }
    }

    postUpdatedTagFile = (fileName, fileData) => {
        return new Promise((resolve, reject) => {
            try {
                const params = {
                    'tagList': fileData,
                    'key': fileName,
                }
                postTag(params)
                    .then(response => {
                        return resolve(response.data);
                    });
            } catch (ex) {
                return reject(ex);
            }
        })
    }

    onSave = () => {
        this.setState({
            uploadInProgress: true,
        })
        try {
            Promise.all([
                this.postUpdatedTagFile('subjectTags.json', this.state.subjectTagList),
                this.postUpdatedTagFile('vehicleTags.json', this.state.vehicleTagList),
                this.postUpdatedTagFile('groupTags.json', this.state.tagGroupList)])
                .then(response => {
                    this.setState({
                        uploadInProgress: false,
                    });
                    this.notificationDialog.handleOpen(`Successfully updated tags!`);
                });
        }
        catch (ex) {
            this.notificationDialog.handleOpen(`There was an issue loading tag groups. Please try again or contact the dev team.`);
            console.log(JSON.stringify(ex));
            this.setState({
                uploadInProgress: false,
            })
        }
    }

    handleDeleteDialogClose = () => {
        this.setState({
            isDeleteDialogOpen: false,
        })
    }

    handleDeleteDialogOpen = () => {
        this.setState({
            isDeleteDialogOpen: true,
        })
    }

    deleteTagGroup = () => {
        try {
            const groupName = this.state.selectedTagGroup ? this.state.selectedTagGroup.groupName : undefined;
            const tagGroupCopy = this.state.tagGroupList;
            const groupPosition = tagGroupCopy.findIndex(group => group.groupName.trim() === groupName.trim());
            if (groupPosition !== -1) {
                tagGroupCopy.splice(groupPosition, 1);
                this.postUpdatedTagFile('groupTags.json', tagGroupCopy)
                    .then(response => {
                        this.setState({
                            tagGroupListNames: [],
                            tagGroupList: [],
                            selectedTagGroup: null,
                            subjectTagList: [],
                            uploadInProgress: false,
                            vehicleTagList: [],
                        });
                        this.handleDeleteDialogClose();
                        this.notificationDialog.handleOpen('Successfully deleted "' + groupName + '" tag group.');
                        this.getAllTagData();
                    });
            } else {
                this.handleDeleteDialogClose();
                this.notificationDialog.handleOpen('Failed to delete "' + groupName + '" tag group.');
            }
        }
        catch (ex) {
            this.notificationDialog.handleOpen('Unable to delete tag group. Please try again or reach out to dev team.');
            console.log(JSON.stringify(ex));
        }
    }

    handleCreateDialogClose = () => {
        this.setState({
            isCreateDialogOpen: false,
        })
    }

    handleCreateDialogOpen = () => {
        this.setState({
            isCreateDialogOpen: true,
        })
    }

    createNewTagGroup = (groupName) => {
        const newGroup = {
            'groupName': groupName,
            'subjectTags': [],
            'vehicleTags': [],
        }
        const updatedTagGroupList = this.state.tagGroupList.concat(newGroup);
        const updatedTagGroupNames = this.state.tagGroupListNames.concat(groupName);

        this.setState({
            tagGroupList: updatedTagGroupList,
            tagGroupListNames: updatedTagGroupNames,
            selectedTagGroup: newGroup,
        })
    }

    render() {
        const curValue = this.state.selectedTagGroup ? this.state.selectedTagGroup.groupName : undefined;
        return (
            <div>
                <React.Fragment>
                    <TagTypeSelection title={'Select Tag Group'} tagOptions={this.state.tagGroupListNames} handleSelection={this.updateSelectedTagGroup.bind(this)} selectedValue={curValue} />
                    {this.CreateGroupDialog()}
                </React.Fragment>
                {this.state.uploadInProgress
                    && <CircularProgress style={{ margin: "20px" }} size={50} />
                }
                {this.state.selectedTagGroup
                    && <React.Fragment>
                        {this.state.isDeleteDialogOpen
                            ? this.DeleteGroupDialog(this.state.selectedTagGroup.groupName)
                            : <Button hoverColor={'red'} onClick={this.handleDeleteDialogOpen.bind(this)} >Delete Group</Button>
                        }
                        {this.GroupItemsDisplay(this.state.selectedTagGroup)}
                    </React.Fragment>
                }
                <NotificationDialog ref={dialogRef => (this.notificationDialog = dialogRef)} />
            </div>
        );
    }

    DeleteGroupDialog = (groupName) => {
        const actions = [
            <Button
                color='secondary'
                onClick={this.handleDeleteDialogClose}
            >Cancel</Button>,
            <Button
                color='primary'
                keyboardFocused={true}
                onClick={this.deleteTagGroup}
            >Delete</Button>,
        ]
        return (
            <Dialog
                open={this.state.isDeleteDialogOpen}
                onRequestClose={this.handleDeleteDialogClose}>
                <DialogContentText>
                    {`Are you sure you want to delete '${groupName}'?`}
                </DialogContentText>
                <DialogActions>
                    {actions}
                </DialogActions>
            </Dialog>
        )
    }

    CreateGroupDialog = () => {
        return (
            <DialogPopup
                className='Tag-Groups'
                label='Create New Group'
                title='Create New Group'
                hintText='Enter group name'
                inputText='Group Name'
                style={{ margin: "10px" }}
                onSubmitFunction={this.createNewListGroup.bind(this)}
            />
        )
    }

    GroupItemsDisplay = (listItems) => {
        return (
            <div className="Tag-Groups">
                <div className="Left-Column">
                    <AutoCompleteDropDown className='Tags-Dropdown' title='Add Subject Tags' items={this.state.subjectTagList} onItemSelected={this.addSubjectTag} />
                    <ListEditor
                        createNewListItem={this.createNewSubjectTag}
                        removeListItem={this.removeSubjectTag}
                        listItems={listItems.subjectTags || []}
                        itemType={'Subject'}
                        onSave={this.onSave}
                    />
                </div>
                <div className="Right-Column">
                    <AutoCompleteDropDown className='Tags-Dropdown' title='Add Vehicle Tags' items={this.state.vehicleTagList} onItemSelected={this.addVehicleTag} />
                    <ListEditor
                        createNewListItem={this.createNewVehicleTag}
                        removeListItem={this.removeVehicleTag}
                        listItems={listItems.vehicleTags || []}
                        itemType={'Vehicle'}
                        onSave={this.onSave}
                    />
                </div>
            </div >
        );
    }
}