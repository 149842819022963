import React from 'react';
import {
    Chip,
} from '@material-ui/core';
import {
    Check as CheckIcon,
    Edit as EditIcon
} from '@material-ui/icons';

function ScheduledChip(props) {
    const formattedDate = props.date && Intl.DateTimeFormat("en-us", { month: "2-digit", day: "2-digit", year: "2-digit" }).format(Date.parse(props.date));

    let style = {};
    if (props.expired) {
        style = { width: '100%', color: "#616161" };
    } else {
        style = { backgroundColor: '#57C2D8', color: '#FFFFFF', width: '100%' };
    }

    return (
        <Chip
            icon={props.onClick ? <EditIcon style={{ color: '#FFFFFF' }} /> : <CheckIcon style={ props.expired ? {} : { color: '#FFFFFF' }} /> }
            label={typeof formattedDate === 'string' ? formattedDate : 'Scheduled'}
            size="small"
            style={style}
            onClick={props.onClick}
        />
    );
}

export default ScheduledChip;
