import React, { useEffect, useState } from 'react';
import ActivityCard from '../../components/ActivityCard';
import {
    Schedule as ScheduleIcon,
} from '@material-ui/icons';
import { getScheduledContent } from '../../apiService';


function ScheduledContentCard(props) {
    const [scheduleData, setScheduleData] = useState(null);
    const [tableData, setTableData] = useState();
    const [showAll, setShowAll] = useState(false);
    const [greaterThanLimit, setGreaterThanLimit] = useState();
    const rowLimit = 4;

    useEffect(() => {
        // Get schedule data from misson-control-api
        getScheduledContent()
            .then(data => {
                if (data) {
                    setScheduleData(data);
                } else {
                    console.log("Error getting scheduled content");
                }
            })
    }, []);

    useEffect(() => {
        formatScheduleData();
        setGreaterThanLimit(scheduleData?.length > rowLimit);
    }, [scheduleData, showAll]);


    function formatDate(dateTime) {
        return new Date(dateTime).toLocaleString('en-US', { timeZone: "America/Chicago", dateStyle: 'short', timeStyle: 'short' });
    }

    function compareDates(dateA, dateB) {
        const a = new Date(dateA);
        const b = new Date(dateB);
        if (a > b) return -1;
        if (b < a) return 1;
        return 0;
    }

    function formatScheduleData() {
        if(scheduleData){
            let formattedData = [];
            scheduleData.map(item => formattedData.push({
                title: item.title, 
                dateTime: formatDate(item.scheduledTime), 
                type: item.contentType
            }));
            formattedData.sort((itemA, itemB) => compareDates(itemB.dateTime, itemA.dateTime));
            if(!showAll){
                formattedData = formattedData.slice(0, rowLimit);
            }
            setTableData(formattedData);
        }
    }

    function toggleShowAll(){
        setShowAll(!showAll);
    }

    return (
        <ActivityCard
            titleIcon={<ScheduleIcon />}
            title='Upcoming Scheduled Content'
            tableData={tableData}
            variant='scheduled'
            allowAccess={props.allowAccess}
            showAllFunction={greaterThanLimit ? toggleShowAll : null}
        />
    );
}

export default ScheduledContentCard;