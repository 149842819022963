import React from 'react';
import Button from '@material-ui/core/Button';
import { FilledInput } from '@material-ui/core';

export default class FileUploader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileName: "",
            buttonTitle: props.title,
            reuploadTitle: props.reuploadTitle
        };

        this.addFile = this.addFile.bind(this);
    }

    addFile(event) {
        if (event.target.files[event.target.files.length - 1]) {
            let file = event.target.files[event.target.files.length - 1]
            if (file.name.trim().includes('.xlsx')) {
                this.setState({
                    fileName: file.name,
                    buttonTitle: this.state.reuploadTitle
                });
            }
            this.props.addFile(event);
        }
    }

    render() {
        return (
            <div>
                <input
                    id={this.props.title}
                    onChange={this.addFile}
                    accept={this.props.onlyXlsx ? ".xlsx" : "*"}
                    style={{ display: "none" }}
                    type="file" />
                <p>{this.state.fileName}</p>
                <label htmlFor={this.props.title}>
                    <Button variant='contained' component='span' color='secondary'>{this.state.buttonTitle}</Button>
                </label>
            </div>
        );
    }
}