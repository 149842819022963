import React from 'react';
import PropTypes from 'prop-types';
import '../../App.css';
import Button from '@material-ui/core/Button';
import ListComponent from '../../components/List';
import DialogPopup from '../../components/Dialog';

export default function ListEditor(props) {
    return (
        <React.Fragment>
            <div className="Tag-Type">
                <h2>
                    <p>{props.itemType + ' Tags:'}</p>
                </h2>
            </div>
            <div style={{ display: "inline-block" }}>
                {props.resetList
                    ? <Button variant='contained'
                        style={{ display: "flex", justifyContent: "center", width: "100px", marginLeft: "0px", marginBottom: "10px" }}
                        color='primary'
                        onClick={props.resetList}
                    >Back</Button>
                    : null}
                <ListComponent
                    className="Edit-Tag-List"
                    items={props.listItems}
                    removeListItem={props.removeListItem}
                    maxHeight={350}
                    listType={props.itemType}
                />
                <br />
                <DialogPopup
                    primary={false}
                    secondary={true}
                    style={{ marginBottom: "20px" }}
                    label="Create New Tag"
                    title="Create Subject Tags"
                    hintText="Enter tag name"
                    inputText="Subject Tag"
                    onSubmitFunction={props.createNewListItem}
                />
                <Button variant='contained'
                    style={{ margin: "20px" }}
                    color='secondary'
                    onClick={props.onSave}
                >Save Updates</Button>
            </div>
        </React.Fragment>
    )
}

ListEditor.propTypes = {
    createNewListItem: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    removeListItem: PropTypes.func.isRequired,
    resetList: PropTypes.func,
    listItems: PropTypes.array.isRequired,
    itemType: PropTypes.string.isRequired,
}