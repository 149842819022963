import { useState } from 'react';
import '../../App.css';
import { Button, TextField } from '@material-ui/core';

function CreateGuestUserComponent() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    // TODO: Pass guestUser to the Guest User lambda
    let guestUser = {firstName: firstName, lastName: lastName, email: email};

    const createGuestUser = () => {
        console.log(`First name: ${firstName}, Last name: ${lastName}, Email: ${email}`);
    };

    return (
        <div className="create-user-container">
            <div className="create-user-item">
                <p>First Name:</p>
                <TextField value={firstName} onChange={(event) => {setFirstName(event.target.value);}} >
                </TextField>
            </div>
            <div className="create-user-item">
                <p>Last Name:</p>
                <TextField value={lastName} onChange={(event) => {setLastName(event.target.value);}} >
                </TextField>
            </div>
            <div className="create-user-item">
                <p>Email:</p>
                <TextField className="create-user-email" value={email} onChange={(event) => {setEmail(event.target.value);}} >
                </TextField>
            </div>
            <Button variant='contained' color={true ? 'primary' : 'default'} onClick={createGuestUser} disabled={!firstName || !lastName || !email}>Create</Button>
        </div>
    )
}

export default CreateGuestUserComponent;