import React from 'react';
import {
    Card,
    CardContent,
    Hidden,
    Typography,
    Tooltip,
    Fab,
} from '@material-ui/core';


function QuickActionCard(props) {
    return (
        <Card elevation={3}>
            <CardContent>
                <div style={{ display: 'flex', flexDirection: 'flex-row', justifyContent: 'space-between', gap: '10px', alignItems: 'center', maxWidth: '100%' }}>
                    <Typography noWrap variant='h6' align='left' style={{ display: 'flex', flexDirection: 'flex-row', justifyItems: 'space-between', gap: '10px', alignItems: 'center' }} >
                        {props.titleIcon}
                        {props.title}
                    </Typography>
                    <Tooltip arrow enterDelay={1000} title={props.allowAction ? '' : `You don't have permission to take this action.`} >
                        <span>
                            <Fab color={props.actionColor} size='small' disabled={!props.allowAction} href={props.link} style={{ alignSelf: 'flex-end' }} >
                                {props.actionIcon}
                            </Fab>
                        </span>
                    </Tooltip>
                </div>
            </CardContent>
        </Card>
    );
}

export default QuickActionCard;
