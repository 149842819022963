import React, { useEffect, useState } from 'react';
import ActivityCard from '../../components/ActivityCard';
import {
    ErrorOutline as ErrorIcon,
} from '@material-ui/icons';


function RecentErrorsCard(props) {
    const [errorData, setErrorData] = useState();
    const [tableData, setTableData] = useState();

    useEffect(() => {
        // Get error data from misson-control-api
        // getErrorData().then(data => setErrorData(data));
    }, []);

    useEffect(() => {
        // Format error data to table array of objects consumable by ActivityCard component
        // setTableData();
    }, [errorData]);

    return (
        <ActivityCard
            titleIcon={<ErrorIcon />}
            title='Recent Errors'
            tableData={tableData}
            variant='error'
            comingSoon
        />
    );
}

export default RecentErrorsCard;