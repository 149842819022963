import React from 'react';
import NotificationPreview from './notificationPreview';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

function PreviewSection({
    announcement,
    message,
    outage,
    push,
    redirectButtonLabel,
    selectedCategoryIcon,
    showSkeleton,
    shouldRedirect,
    title,
}) {
    if(showSkeleton) return <Skeleton variant='rect' height={300} />;

    return <div className='previews-section' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px', maxWidth: '1124px', paddingBottom: '25px' }} >
        {push && <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '24px', maxWidth: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <Typography variant='caption' >Android Push Notification:</Typography>
                <Typography variant='caption' style={{ color: '#58595B' }} >65 characters for title, 240 for message</Typography>
                <NotificationPreview
                    notificationType='android'
                    title={title}
                    message={message}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <Typography variant='caption' >iOS Push Notification:</Typography>
                <Typography variant='caption' style={{ color: '#58595B' }} >178 characters for message</Typography>
                <NotificationPreview
                    notificationType='ios'
                    title={title}
                    message={message}
                />
            </div>
        </div>}
        {announcement && <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '24px', maxWidth: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <Typography variant='caption' >Desktop Announcement:</Typography>
                <Typography variant='caption' style={{ color: '#58595B' }} >Message Hidden After Two Lines</Typography>
                <NotificationPreview
                    notificationType='desktopAnnouncement'
                    title={title}
                    message={message}
                    selectedCategoryIcon={selectedCategoryIcon}
                    redirectButtonLabel={redirectButtonLabel}
                    shouldRedirect={shouldRedirect}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <Typography variant='caption' >Tablet Announcement:</Typography>
                <Typography variant='caption' style={{ color: '#58595B' }} >Message Hidden After Three Lines</Typography>
                <NotificationPreview
                    notificationType='tabletAnnouncement'
                    title={title}
                    message={message}
                    selectedCategoryIcon={selectedCategoryIcon}
                    redirectButtonLabel={redirectButtonLabel}
                    shouldRedirect={shouldRedirect}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <Typography variant='caption' >Mobile Announcement:</Typography>
                <Typography variant='caption' style={{ color: '#58595B' }} >Message Hidden After Three Lines</Typography>
                <NotificationPreview
                    notificationType='mobileAnnouncement'
                    title={title}
                    message={message}
                    selectedCategoryIcon={selectedCategoryIcon}
                    redirectButtonLabel={redirectButtonLabel}
                    shouldRedirect={shouldRedirect}
                />
            </div>
        </div>}
        {outage && <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '24px', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%',  }} >
                <Typography variant='caption' >Outage Alert:</Typography>
                <NotificationPreview
                    notificationType='outage'
                    title={title}
                    message={message}
                />
            </div>
        </div>}

    </div>;
}

export default PreviewSection;