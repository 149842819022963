import React, { useEffect, useState, useContext } from 'react';
import NotificationForm from '../../components/NotificationForm/notificationForm';
import PreviewSection from '../../components/NotificationPreviews/previewsSection';
import config from '../../config';
import '../../App.css';
import { FeedbackContext } from '../../components/FeedbackSnackbar';
import { getNotificationCategories } from '../../apiService';

// MATERIAL-UI COMPONENTS
import {
    Container,
} from '@material-ui/core';

// API SERVICE
import {
    getCategories, // get categories & corresponding icon paths
    getAnnouncement,
    getAnnouncements,
    editNotification, // edit notification in lower environment
} from '../../apiService';

function EditNotificationComponent() {
    const [categories, setCategories] = useState();
    const [notification, setNotification] = useState(null);
    const [loading, setLoading] = useState(false);
    const urlId = new URL(document.location).searchParams.get('id');
    const { snackbar, setSnackbar } = useContext(FeedbackContext);

    useEffect(() => {
        if (!notification?.id) {
            // getAnnouncement(urlId)
            // .then(data => {
            //     console.log(data);
            //     setNotification(data);
            // })
            getAnnouncements()
                .then(data => {
                    const matchingNotification = data.find(item => item.id === urlId);
                    if (matchingNotification) {
                        setNotification(matchingNotification);
                    } else {
                        console.log(`Unable to find notification with id of ${urlId}`)
                        // navigate to create notification page if id not found
                        window.location.pathname = '/notifications/create'
                    }

                })
        }
    }, []);

    useEffect(() => {
        if (!categories) {
            getNotificationCategories()
                .then(data => {
                    if (data) {
                        setCategories(data.categories);
                    } else {
                        console.log("Error getting notificaion categories");
                    }
                })
        }
    }, []);

    function editExistingNotification() {
        const today = new Date();
        const newNotification = {
            ...notification,
            lastModifiedDate: today,
            editContent: true,
        };
        setLoading(true);


        editNotification(newNotification).then(() => {
            // store snackbar state for access after redirect
            window.history.pushState({snackbar: {open: true, success: true, message: "Notification saved successfully!"}}, '', '/notifications/activity')
            window.location.pathname = '/notifications/activity';
        })
        .catch((err) => {
            setLoading(false);
            setSnackbar({open: true, success: false, messsage: "Notification failed to save."});
        });
        console.log(`Saving notification to ${config.environments.formattedSourceEnvironment}...`);
        console.log(newNotification);
    }

    return <Container style={{ marginTop: '25px' }} >
        <NotificationForm
            categories={categories}
            notification={notification}
            setNotification={setNotification}
            save={editExistingNotification}
            saveLabel={`Save Changes to ${config.environments.formattedSourceEnvironment}`}
            loading={loading}
        />

        <PreviewSection
            push={notification?.isPush}
            announcement={notification?.category !== 'Outage Alert'}
            outage={notification?.category === 'Outage Alert'}
            title={notification?.title || 'Your Title Here'}
            message={notification?.message || 'Your Message Here'}
            showSkeleton={notification === undefined}
            selectedCategoryIcon={notification?.icon}
            shouldRedirect={!!notification?.redirectPath}
            redirectButtonLabel={"Learn More"}
        />
    </Container>;
}

export default EditNotificationComponent;