import { useState, createContext } from "react";
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const FeedbackContext = createContext(null);

const FeedbackSnackbarWrapper = (props) => {
    const [snackbar, setSnackbar] = useState({open: false});

    const FeedbackSnackbar = () => {
        return (
            <Snackbar 
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={()=>setSnackbar({open: false})}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                <Alert onClose={()=>setSnackbar({open: false})} severity={snackbar.success ? "success" : "error"}>
                {snackbar.message}
                </Alert>
            </Snackbar>
        )
    }

    return (
        <FeedbackContext.Provider value={{ snackbar, setSnackbar }}>
            {props.children}
            <FeedbackSnackbar />
        </FeedbackContext.Provider>
    )
}

export { FeedbackSnackbarWrapper, FeedbackContext };