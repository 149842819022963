import React from 'react';
import {
    Grid,
    TextField,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from '@material-ui/core';
import {
    Search as SearchIcon,
    Add as AddIcon
} from '@material-ui/icons';

function ActivityToolbar(props) {
    return (
        <Grid className='filter-toolbar' container spacing={2} justifyContent="space-between" alignItems="center" style={{ marginBottom: 0 }}>
            <Grid item xs={6} md >
                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Search"
                    variant="outlined"
                    value={props.filter?.text}
                    onChange={event => props.handleTextChange(event.target.value)}
                    InputProps={{ endAdornment: <InputAdornment position="end" style={!props.filter?.text ? { color: '#7d7d7d' } : {}} ><SearchIcon /></InputAdornment> }}
                />
            </Grid>
            
            <Grid item xs={6} md >
                <FormControl variant="outlined" fullWidth >
                    <InputLabel id="category-select-label">Filter by Category</InputLabel>
                    <Select
                        labelId="category-select-label"
                        id="category-select"
                        value={props.filter?.category}
                        label="Filter by Category"
                        style={props.filter?.category === 'Show All' ? { textAlign: 'left', color: '#7d7d7d' } : { textAlign: 'left' }}
                        onChange={event => props.handleCategoryChange(event.target.value)}
                    >
                        <MenuItem value='Show All' >Show All</MenuItem>
                        {props.categories?.map(category => <MenuItem key={category} value={category}>{category}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6} md >
                <FormControl variant="outlined" fullWidth >
                    <InputLabel id="type-select-label">Filter by Type</InputLabel>
                    <Select
                        labelId="type-select-label"
                        id="type-select"
                        value={props.filter?.type}
                        label="Filter by Type"
                        style={props.filter?.type === 'Show All' ? { textAlign: 'left', color: '#7d7d7d' } : { textAlign: 'left' }}
                        onChange={event => props.handleTypeChange(event.target.value)}
                    >
                        <MenuItem value='Show All' >Show All</MenuItem>
                        {props.notificationTypes?.map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6} md >
                <FormControl variant="outlined" fullWidth >
                    <InputLabel id="status-select-label">Filter by Status</InputLabel>
                    <Select
                        labelId="status-select-label"
                        id="status-select"
                        value={props.filter?.status}
                        label="Filter by Status"
                        style={props.filter?.status === 'Show All' ? { textAlign: 'left', color: '#7d7d7d' } : { textAlign: 'left' }}
                        onChange={event => props.handleStatusChange(event.target.value)}
                    >
                        <MenuItem value='Show All' >Show All</MenuItem>
                        {props.statuses?.map(status => <MenuItem key={status} value={status}>{status}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6} md >
                <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    color="primary"
                    startIcon={<AddIcon />}
                    style={{ height: '56px' }}
                    href='/notifications/create'
                >New Notification</Button>
            </Grid>
        </Grid>
    );
}

export default ActivityToolbar;
