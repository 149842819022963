import React from 'react';
import {
    Chip
} from '@material-ui/core';
import {
    Archive as ArchiveIcon,
} from '@material-ui/icons';

function ArchivedChip() {
    return (
        <Chip
            icon={<ArchiveIcon />}
            size="small"
            label='Archived'
            style={{ width: '100%', color: "#616161" }}
        />
    );
}

export default ArchivedChip;
