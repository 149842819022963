import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import '../../App.css';

export default function DialogPopup(props) {
    const [open, setOpen] = useState(false);
    const [input, setInput] = useState('');

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        props.onSubmitFunction(input)
        handleClose();
    }

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                {props.label}
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <div className='dialogPopup'>
                    <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {props.hintText}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            label={props.inputText}
                            value={input}
                            onChange={handleInputChange}
                            type="text"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSave} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}