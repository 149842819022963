import React, { useEffect, useMemo, useState } from 'react';
import './notificationPreview.css';
import ToyotaLogo from './toyota_notif_logo.png';
import InfoIcon from './info_icon.png';
import IconCloseWhite from './icon_close_white.png';
import { getImageUrl, applyMarkdown, removeMarkdown } from '../../utils';

const NotificationPreview = ({
    message,
    notificationType,
    redirectButtonLabel,
    selectedCategoryIcon,
    shouldRedirect,
    title,
}) => {

    const CHARS_OFFSET = "See more".length;
    const [isExpandable, setIsExpandable] = useState(false);
    const [notificationContent, setNotificationContent] = useState(message);
    const [isExpanded, setIsExpanded] = useState(false);

    const charsLimitPerNotificationType = {
        outage: 188,
        desktopAnnouncement: 188 - CHARS_OFFSET,
        tabletAnnouncement: 120 - CHARS_OFFSET,
        mobileAnnouncement: 90 - CHARS_OFFSET   
    }
    
    const sliceByChars = (text, limitCharsNumber) => {
        if (text && text.length <= limitCharsNumber) {
            setIsExpandable(false)
            return text;
        }

        setIsExpandable(true)
        return `${text.slice(0, limitCharsNumber)}...`;
    }

    const calculateExpandable = (message, notificationType) => {
        const charLimit = charsLimitPerNotificationType[notificationType];
        const slicedText = sliceByChars(message, charLimit);

        setNotificationContent(slicedText)
    }

    useEffect(() => {
        if (!['android', 'ios'].includes(notificationType)) {
            calculateExpandable(message, notificationType);
        }
    }, [message]);

    const androidNotif = useMemo(() => (
        <div className="androidNotifContainer">
            <div className="androidHeader">
                <img className="androidIcon" src={ToyotaLogo}/>
                <div className="androidHeaderText">Toyota Engage  •  now </div>
            </div>
            <div className="androidBody">
                <div className="androidTitle">{removeMarkdown(sliceByChars(title, 65), true)}</div>
                <div className="androidMessage">{removeMarkdown(sliceByChars(message, 240))}</div>
            </div>
        </div>
    ), [title, message]);

    const iosNotif = useMemo(() => (
        <div className="iosNotifContainer">
            <img className="iosIcon" src={ToyotaLogo}/>
            <div className="iosText">
                <div className="iosTitle truncate-text-one-line">{removeMarkdown(title)}</div>
                <div className="iosMessage">{removeMarkdown(sliceByChars(message, 178))}</div>
            </div>
            <div className="iosTimeStamp">now</div>
        </div>
    ), [title, message]);

    const outageNotif = (
        <div className="alert-banner-container">
            <div className="text">
                <div className="dismiss-icon" onClick={() => {}}>
                    <a><img src={IconCloseWhite} alt='X' /></a>
                </div>
                <div className="header-text">{applyMarkdown(title)}</div>
                <span className="message-text">{applyMarkdown(message)}</span>
            </div>
        </div>
    )

    const announcement = (device) => {
        const showRedirectButton = shouldRedirect && redirectButtonLabel
        return(
        <div className={`announcementContainer`} style={showRedirectButton ? { paddingBottom: '48px' } : {}}>
            <div className={`${device}Container`}>
                {selectedCategoryIcon ? 
                    <img src={getImageUrl(selectedCategoryIcon)} style={{ maxHeight: '24px', marginRight: '8px' }} /> :
                    <img className="announcementIcon" src={InfoIcon}/>
                }
                <div className={`${device}AnnouncementText`}>
                    <div className="announcementTitle">{applyMarkdown(title, true)}</div>
                    <div className="announcementMessage">
                        {applyMarkdown(!isExpanded ? notificationContent : message, true)}
                        {isExpandable && !isExpanded && (
                            <span className='see-more' onClick={() => setIsExpanded(true)}>See More</span>
                        )}
                        {shouldRedirect && redirectButtonLabel && (
                            <span className='redirect-button'>{redirectButtonLabel}</span>
                        )}
                    </div>
                </div>
                <div className='announcementTimestamp'>30 min</div>
            </div>
            {isExpandable && isExpanded && <div className='see-less-container'><span className='see-less' onClick={() => setIsExpanded(false)}>See less</span></div>}
        </div>);
    }

    const getNotificationPreview = (notifType) => {
        switch(notifType){
            case 'android':
                return androidNotif;
            case 'ios':
                return iosNotif;
            case 'outage':
                return outageNotif;
            case 'desktopAnnouncement':
                return announcement('desktop');
            case 'tabletAnnouncement':
                return announcement('tablet');
            case 'mobileAnnouncement':
                return announcement('mobile');
            default: return;
        }
    }

    return(
        <div>
            {getNotificationPreview(notificationType)}
        </div>
    );

}

export default NotificationPreview;
