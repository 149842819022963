import React from 'react';
import {
    Chip,
} from '@material-ui/core';
import {
    Check as CheckIcon,
    Edit as EditIcon,
} from '@material-ui/icons';

function ExpirationChip(props) {
    const formattedDate = props.date && Intl.DateTimeFormat("en-us", { month: "2-digit", day: "2-digit", year: "2-digit" }).format(Date.parse(props.date));
    let style = {};

    if (props.expired) {
        style = { width: '100%', color: "#616161" };
    } else if (props.scheduled) {
        style = { backgroundColor: '#57C2D8', color: '#FFFFFF', width: '100%' };
    } else if (props.promoted) {
        style = { backgroundColor: '#7A306C', color: '#FFFFFF', width: '100%' };
    } else {
        style = { width: '100%' };
    }

    return (
        <Chip
            icon={props.expired ? <CheckIcon /> : <EditIcon style={{ color: '#FFFFFF' }} />}
            label={formattedDate}
            size="small"
            style={style}
            onClick={props.onClick}
        />
    );
}

export default ExpirationChip;
