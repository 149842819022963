import React, { useEffect, useState } from 'react';
import { getImageUrl } from '../../utils';

// MATERIAL-UI COMPONENTS
import {
    Paper,
    Box,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Switch,
    Button,
    Modal,
    IconButton,
    Typography,
    CircularProgress
} from '@material-ui/core';

// MATERIAL ICONS
import {
    CheckCircle as SaveIcon,
    Publish as PublishIcon,
    Close as CloseIcon,
} from '@material-ui/icons';
import { Skeleton, Alert } from '@material-ui/lab';
import DialogV2 from '../DialogV2';
import config from '../../config';

// EXPECTED PROPS
//   - notification
//         Object with 'title' string, 'message' string, 'category' string, and 'isPush' boolean
//         Used to load existing data into form (if editing a notification) and keep track of changes
//   - setNotification
//         Function to update the notification object
//   - save
//         Function to execute when clicking the save button
//   - saveLabel
//         String text to display on save button
//   - saveIcon
//         Boolean - display save icon if true (editing), otherwise default to Publish icon
//   - categories
//         Array of objects with 'label' string & 'iconPath' string

function NotificationForm(props) {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!props.notification) return;

        let notification = {...props.notification}

        // If it's an Outage Alert, it's also a Push notification
        if (props.notification.category === 'Outage Alert') {
            notification.isPush = true;
        }

        // If the title is blank, autofill the category name
        const categoryLabels = props.categories?.map(c => c.label);
        if (props.notification.title === '' || categoryLabels?.includes(props.notification.title)) {
            notification.title = props.notification.category
        }

        // Map category to icon
        const selectedCategoryData = props.categories?.find(c => c.label === props.notification.category);
        if (selectedCategoryData) {
            notification.icon = selectedCategoryData.iconPath
        }

        props.setNotification(notification);

    }, [props.notification?.category]);

    function handleInputChange(value, field) {
        const updatedData = { ...props.notification };
        updatedData[field] = value;
        props.setNotification(updatedData);
    }

    function handleSave() {
        if (props.notification.title === '' ||
            props.notification.message === '' ||
            props.notification.category === '') {
            setShowModal(true);
        } else {
            props.save()
        }
    }

    function closeModal() {
        setShowModal(false);
    }

    if (!props.notification) return <Skeleton variant='rect' height={200} style={{ marginBottom: '25px' }} />;

    return <Box style={{ display: 'flex', flexDirection: 'column', gap: '24px', paddingBottom: '24px' }} >
        <Box style={{ display: 'flex', gap: '24px' }} >
            <Paper>
                <FormControl variant='outlined' style={{ width: '244px', textAlign: 'left' }} disabled={props.loading} >
                    <InputLabel id='category-select-label'>Category</InputLabel>
                    <Select
                        labelId='category-select-label'
                        id='category-select'
                        value={props.notification?.category}
                        onChange={(event) => handleInputChange(event.target.value, 'category')}
                        label='Category'
                    >
                        {props.categories?.map(category =>
                            <MenuItem key={category.label} value={category.label} >
                                <img src={getImageUrl(category.iconPath)} style={{ maxHeight: '24px', marginRight: '8px' }} />
                                {category.label}
                            </MenuItem>)}
                    </Select>
                </FormControl>
            </Paper>

            <Paper style={{ width: '50%' }} >
                <TextField
                    variant='outlined'
                    fullWidth
                    label='Title'
                    value={props.notification?.title}
                    onChange={(event) => handleInputChange(event.target.value, 'title')}
                    inputProps={{
                        maxLength: 125,
                    }}
                    disabled={props.loading}
                />
            </Paper>

            <Paper style={{ width: '50%' }} >
                <TextField
                    variant='outlined'
                    fullWidth
                    label='Path'
                    value={props.notification?.redirectPath}
                    onChange={(event) => handleInputChange(event.target.value, 'redirectPath')}
                    inputProps={{
                        maxLength: 125,
                    }}
                    disabled={props.loading}
                />
            </Paper>
        </Box>
        <Box style={{ display: 'flex', gap: '24px' }} >
            <Paper style={{ width: '100%' }} >
                <TextField
                    variant='outlined'
                    multiline
                    rows={4}
                    fullWidth
                    label='Message'
                    value={props.notification?.message}
                    onChange={(event) => handleInputChange(event.target.value, 'message')}
                    disabled={props.loading}
                />
            </Paper>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'center', gap: '24px' }} >
            <FormControlLabel
                control={
                    <Switch
                        checked={props.notification?.isPush}
                        onChange={() => handleInputChange(!props.notification?.isPush, 'isPush')}
                        color='secondary'
                    />
                }
                label='App Push Notification'
                disabled={props.loading}
            />

            <Button
                variant='contained'
                style={{ backgroundColor: '#F8D76B', color: '#58595B' }}
                size='large'
                startIcon={props.loading ? <CircularProgress size="20px" color="inherit" /> : props.saveIcon ? <SaveIcon /> : <PublishIcon />}
                onClick={handleSave}
                disabled={props.loading}
            >
                {props.saveLabel}
            </Button>
        </Box>
        <DialogV2
            open={showModal}
            title={"Error Saving Notification"}
            content={<Alert severity="error">All notifications must have a <strong>Category</strong>, <strong>Title</strong> & <strong>Message</strong> - make sure none of these fields are blank and try again.</Alert>}
            actions={
                <Button
                    onClick={closeModal}
                    style={{ color: '#FFFFFF', backgroundColor: '#EB0A1E' }}
                    variant="contained"
                    autoFocus
                >Dismiss</Button>
            }
        />

    </Box >;
}

export default NotificationForm;