import React from 'react';
import {
    Grid,
    Typography,
    Chip,
} from '@material-ui/core';

function ActivityFilterChips(props) {
    return (
        <Grid className='filter-chips' container spacing={2} alignItems="center" style={{ marginBottom: 0, padding: '8px' }}>
            {(props.filter?.text || props.filter?.category !== 'Show All' || props.filter?.type !== 'Show All') ? (
                <Typography variant='h6' >Filters:</Typography>
            ) : (
                <></>
            )
            }
            {props.filter?.text &&
                <Grid item>
                    <Chip
                        label={<span>Search Text: <strong>{props.filter?.text}</strong></span>}
                        color='primary'
                        onDelete={() => props.handleTextChange('')}
                    />
                </Grid>
            }
            {props.filter?.category !== 'Show All' &&
                <Grid item>
                    <Chip
                        label={<span>Category: <strong>{props.filter?.category}</strong></span>}
                        color='primary'
                        onDelete={() => props.handleCategoryChange('Show All')}
                    />
                </Grid>
            }
            {props.filter?.type !== 'Show All' &&
                <Grid item>
                    <Chip
                        label={<span>Type: <strong>{props.filter?.type}</strong></span>}
                        color='primary'
                        onDelete={() => props.handleTypeChange('Show All')}
                    />
                </Grid>
            }
            {props.filter?.status !== 'Show All' &&
                <Grid item>
                    <Chip
                        label={<span>Status: <strong>{props.filter?.status}</strong></span>}
                        color='primary'
                        onDelete={() => props.handleStatusChange('Show All')}
                    />
                </Grid>
            }
        </Grid>
    );
}

export default ActivityFilterChips;
