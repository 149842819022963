import React, { Component } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { editorConfig } from './ckEditorConfig';

export default class TextEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      embeddedVideoIndex: 0,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleCKEditorChange = this.handleCKEditorChange.bind(this)
    this.imageHandler = this.imageHandler.bind(this)
    this.getToolbarOptions = this.getToolbarOptions.bind(this);
    this.qumuEmbedHandler = this.qumuEmbedHandler.bind(this);
  }

  componentDidMount() {
    this.createCKEditor();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      text: nextProps.text ? nextProps.text : "",
    });
  }

  handleCKEditorChange(editor) {
    const text = editor.data.get();
    this.setState({ text })
    this.props.contentUpdate(text);
  }

  handleChange(value) {
    this.setState({ text: value })
    this.props.contentUpdate(value);
  }

  async imageHandler(image, callback) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      const addFileSuccess = await this.props.addFile(file);
      if (addFileSuccess) {
        const range = await this.quillRef.getEditor().getSelection();
        const bucketEnv = window.location.hostname.includes('test') || window.location.hostname.includes('dev') || window.location.hostname.includes('localhost') ? "dev.nonprod" : "staging";
        const imageUrl = `https://${bucketEnv}.engage.toyota.com/static/articles/embeddedImages/${file.name}`;
        this.quillRef.getEditor().insertEmbed(range.index, 'image', imageUrl, "user");
      }
    };
  }

  async qumuEmbedHandler() {
    const qumuInput = prompt('Please enter a Qumu ID');

    if (qumuInput) {
      const inputRange = await this.quillRef.getEditor().getSelection(qumuInput);
      const videoLink = `https://video-toyota.qumucloud.com/view/${qumuInput}`;
      const req = new XMLHttpRequest();
      this.quillRef.getEditor().insertEmbed(inputRange.index, 'video', videoLink, 'user');
    }
    return null;
  }

  getToolbarOptions() {
    const options = [
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline'],
      ['qumu', 'video', 'image', 'link'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }]
    ];
    return options;
  }

  createCKEditor() {
    window.CKEDITOR.ClassicEditor
      .create(document.getElementById("editor"), editorConfig)
      .then(editor => {
        if (this.props.text) {
          editor.setData(this.props.text);
        }
        editor.model.document.on('change:data', (evt, data) => {
          this.handleCKEditorChange(editor)
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    const ckEditor = document.getElementsByClassName('ck-editor')[0];
    if (ckEditor) {
      ckEditor.style.display = this.props.isCKEditor ? 'block' : 'none';
    }
    return (
      <div className="Text-Editor">
        <div id="editor"></div>
        <ReactQuill
          style={{
            display: this.props.isCKEditor ? 'none' : 'block'
          }}
          modules={{
            toolbar: {
              container: this.getToolbarOptions(),
              handlers: {
                'image': this.imageHandler,
                'qumu': this.qumuEmbedHandler,
              }
            },
          }}
          ref={(el) => this.quillRef = el}
          value={this.state.text}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}