import React from 'react';
import '../../App.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import NotificationDialog from '../../components/NotificationDialog/index.js'
import { getTags, postTag } from '../../apiService.js';
import TagTypeSelection from './tagTypeSelection';
import ListEditor from './listEditor';

export default class EditTagsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldDisplayDialog: false,
            tagOptions: ['Subject', 'Vehicle'],
            tagType: '',
            tags: []

        };
        this.getTagsByType = this.getTagsByType.bind(this);
        this.removeTagFromList = this.removeTagFromList.bind(this);
        this.onSubmitTagsClick = this.onSubmitTagsClick.bind(this);
        this.handleDialogOpen = this.handleDialogOpen.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.createNewTag = this.createNewTag.bind(this);
    }

    async getTagsByType(event) {
        const tagType = event.target.value;
        try {
            // TODO add in loading spinner turn it on here
            let tags = await getTags(tagType.toLowerCase());
            this.setTags(tagType, tags.data);
        }
        catch (error) {
            this.notificationDialog.handleOpen("Error getting tags: " + JSON.stringify(error));
        }
    }

    setTags(tagType, tags) {
        this.setState({
            tagType,
            tags,
        });
    }

    resetTagList() {
        this.setTags(null, null);
    }

    handleDialogOpen = () => {
        this.setState({ shouldDisplayDialog: true });
    };

    handleDialogClose = () => {
        this.setState({ shouldDisplayDialog: false });
    };

    createNewTag(newTag) {
        const cleanedNewTag = newTag.replace(/[^0-9a-z +-.,&$]/gi, '');
        let tagList = [...this.state.tags];
        if (this.tagDoesNotExist(cleanedNewTag, this.state.tags)) {
            tagList.push(cleanedNewTag.trim());
            this.setState({ tags: tagList });
        }
    }

    tagDoesNotExist(newTag, tagList) {
        return !tagList.includes(newTag);
    }

    removeTagFromList = function (listItem, list, category) {
        list = list.slice();
        let index = list.indexOf(listItem);
        if (index > -1) {
            list.splice(index, 1)
        }
        this.setTags(category, list);
    }

    async onSubmitTagsClick() {
        try {
            let fileName = this.state.tagType.toLowerCase() + 'Tags.json';
            await this.uploadUpdatedTagFile(fileName, this.state.tags);
            this.notificationDialog.handleOpen("Successfully updated tags!");
            this.setState({
                shouldDisplayDialog: false,
                tags: [],
                tagType: ''
            });
        }
        catch (error) {
            this.notificationDialog.handleOpen("Error updating tags: " + JSON.stringify(error));
            this.setState({
                shouldDisplayDialog: false,
                tags: [],
                tagType: ''
            });
        }
    }

    uploadUpdatedTagFile(tagFile, tagList) {
        return new Promise((resolve, reject) => {
            try {
                let body = {
                    "tagList": tagList,
                    "key": tagFile
                }
                postTag(body)
                    .then(response => {
                        if (response.data.errorMessage) {
                            reject(response.data.errorMessage);
                        }
                        resolve(response.data);
                    });
            } catch (ex) {
                reject(ex);
            }
        });
    }

    render() {
        const actions = [
            <Button
                color='primary'
                onClick={this.handleDialogClose}
            >Cancel</Button>,
            <Button
                color='primary'
                keyboardFocused={true}
                onClick={this.onSubmitTagsClick}
            >Submit</Button>,
        ];

        return (
            <React.Fragment>
                {this.state.tagType
                    ? <React.Fragment>
                        <ListEditor
                            createNewListItem={this.createNewTag}
                            removeListItem={this.removeTagFromList}
                            listItems={this.state.tags}
                            itemType={this.state.tagType}
                            onSave={this.handleDialogOpen}
                            resetList={this.resetTagList.bind(this)}
                        />
                    </React.Fragment>
                    : <TagTypeSelection tagOptions={this.state.tagOptions} handleSelection={this.getTagsByType} />}
                <Dialog
                    open={this.state.shouldDisplayDialog}
                    onRequestClose={this.handleDialogClose}>
                    <DialogContent>
                        {"Are you sure you want to save your changes? This will update the tags available to use in articles and tag groups."}
                    </DialogContent>
                    <DialogActions>
                        {actions}
                    </DialogActions>
                </Dialog>
                <NotificationDialog ref={dialogRef => (this.notificationDialog = dialogRef)} />
            </React.Fragment>
        );
    }
}

