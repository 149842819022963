import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    CardActions,
    Tooltip,
    Button,
    CircularProgress,
} from '@material-ui/core';
import {
    ArrowForward as ArrowIcon,
    ArrowDownward as DownArrowIcon,
    ArrowUpward as UpArrowIcon
} from '@material-ui/icons';


function ActivityCard(props) {
    const cardStyle = {};
    const spinnerStyle = {};
    const [showAllIcon, setShowAllIcon] = useState(<DownArrowIcon/>);
    const [showAllLabel, setShowAllLabel] = useState("Show All");

    switch (props.variant) {
        case 'activity':
            cardStyle.borderLeft = '5px solid #007DFF';
            spinnerStyle.color = '#007DFF';
            break;
        case 'error':
            cardStyle.borderLeft = '5px solid #EB0A1E';
            spinnerStyle.color = '#EB0A1E';
            break;
        case 'scheduled':
            cardStyle.borderLeft = '5px solid #57C2D8';
            spinnerStyle.color = '#57C2D8';
            break;
        default: break;
    }

    function toggleShowAll() {
        props.showAllFunction();
        if (showAllLabel == "Show All") {
            setShowAllIcon(<UpArrowIcon/>);
            setShowAllLabel("Show Less");
        }
        else {
            setShowAllIcon(<DownArrowIcon/>);
            setShowAllLabel("Show All");
        }
    }

    function renderContent() {
        if (props.comingSoon) {
            return (
                <div style={{ padding: '5%' }}>
                    <Typography color='textSecondary' >
                        {props.title} Coming Soon!
                    </Typography>
                </div>
            );
        }
        if (props.allowAccess === undefined) {
            return (
                <CircularProgress style={spinnerStyle} />
            );
        }
        if (!props.allowAccess) {
            return (
                <div style={{ padding: '5%' }}>
                    <Typography color='textSecondary' >
                        You don't have permission to view this content.
                    </Typography>
                </div>
            );
        }
        if (props.tableData?.length == 0) {
            return (
                <div style={{ padding: '5%' }}>
                    <Typography color='textSecondary' >
                        No {props.title.substr(props.title.indexOf(" ") + 1)}
                    </Typography>
                </div>
            );
        }
        if (props.tableData) {
            return (
                <TableContainer>
                    <Table size='small' >
                        <TableBody>
                            {/*
                            Will only render the columns & tooltips on the array of objects.                                          
                            We expect to see the following columns:                                                        
                            - NotificationActivity: title, titleTooltip, dateTime, dateTimeTooltip, status, statusTooltip  
                            - TemplateActivity: title, dateTime, env, status                                               
                            - ArticleActivity: title, dateTime, env, status                                                
                            - ScheduledContent: title, dateTime, type                                                      
                            - RecentErrors: title, dateTime, type, user, action                                            
                        */}
                            {props.tableData.map((row, index) => (
                                <TableRow key={row.title + index} >
                                    {row.title &&
                                        <TableCell component='th' scope='row' align='left' style={{ maxWidth: '150px' }} >
                                            <Tooltip title={row.titleTooltip || ''} >
                                                <Typography variant='body2' noWrap align='left' >
                                                    {row.title}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                    }
                                    {row.dateTime &&
                                        <TableCell align='left' style={{ maxWidth: '150px' }} >
                                            <Tooltip title={row.dateTimeTooltip || ''} >
                                                <Typography variant='body2' noWrap align='left' >
                                                    {row.dateTime}
                                                </Typography>
                                            </Tooltip>
                                        </TableCell>
                                    }
                                    {row.type &&
                                        <TableCell align='center' style={{ maxWidth: '150px' }} >
                                            <Typography variant='body2' noWrap align='left' >
                                                {row.type}
                                            </Typography>
                                        </TableCell>
                                    }
                                    {row.user &&
                                        <TableCell align='center' style={{ maxWidth: '150px' }} >
                                            <Typography variant='body2' noWrap align='left' >
                                                {row.user}
                                            </Typography>
                                        </TableCell>
                                    }
                                    {row.action &&
                                        <TableCell align='center' style={{ maxWidth: '150px' }} >
                                            <Typography variant='body2' noWrap align='left' >
                                                {row.action}
                                            </Typography>
                                        </TableCell>
                                    }
                                    {row.env &&
                                        <TableCell align='center' style={{ maxWidth: '150px' }} >
                                            {row.env}
                                        </TableCell>
                                    }
                                    {(row.alertIcon || row.pushIcon) &&
                                        <TableCell padding='none' align='center' style={{ maxWidth: '50px' }} >
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }} >
                                                {row.alertIcon}
                                                {row.pushIcon}
                                            </div>
                                        </TableCell>
                                    }
                                    {row.status &&
                                        <TableCell align='center' style={{ maxWidth: '150px' }} >
                                            <Tooltip title={row.statusTooltip || ''} >
                                                <span>
                                                    {row.status}
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        } else {
            return (
                <CircularProgress style={spinnerStyle} />
            );
        }
    }

    return (
        <Card elevation={3} style={cardStyle} >
            <CardContent>
                <div style={{ display: 'flex', width: '100%', marginBottom: '5%' }}>
                    <div style={{ display: 'flex', flexDirection: 'flex-row', justifyItems: 'flex-start', gap: '10px', alignItems: 'center' }}>
                        {props.titleIcon}
                        <Typography variant='h6' align='left' >
                            {props.title}
                        </Typography>
                    </div>
                </div>
                {renderContent()}
            </CardContent >
            {props.seeMore && !props.tableData?.length == 0 ? (
                <CardActions style={{ justifyContent: 'right' }} >
                    <Tooltip arrow title={props.allowAccess ? '' : `You don't have permission to view this content.`} >
                        <span>
                            <Button disabled={!props.allowAccess} variant='text' color='primary' href={props.seeMore} endIcon={<ArrowIcon />} >See More</Button>
                        </span>
                    </Tooltip>
                </CardActions>
            ) : (
                <></>
            )}
            {props.showAllFunction && !props.tableData?.length == 0 ? (
                <CardActions style={{ justifyContent: 'right' }} >
                    <Tooltip arrow title={props.allowAccess ? '' : `You don't have permission to view this content.`} >
                        <span>
                            <Button disabled={!props.allowAccess} variant='text' color='primary' endIcon={showAllIcon} onClick={toggleShowAll}>{showAllLabel}</Button>
                        </span>
                    </Tooltip>
                </CardActions>
            ) : (
                <></>
            )}
        </Card >
    );
}

export default ActivityCard;
