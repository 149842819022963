import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default class DropDown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            items: [],
        };
        this.Items = this.Items.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            items: nextProps.Items
        });
    }

    handleChange(event, index, value) {
        this.setState({ value: event.target.value});
        this.props.handleChange(event, index, value);
    }

    componentWillMount() {
        this.setState({
            items: this.props.Items
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedValue && prevProps.selectedValue !== this.props.selectedValue) {
            this.setState({
                value: this.props.selectedValue,
            })
        }
    }

    Items = function () {
        const items = [];

        if (this.state.items)
            for (let i = 0; i < this.state.items.length; i++) {
                items.push(
                    <MenuItem value={i} >{this.state.items[i]}</MenuItem>
                );
            }

        return items;
    }


    render() {
        return (
            <div>
                <Select 
                value={this.state.value}
                        onChange={this.handleChange}>
                    {this.state.items.map(function (item, index) {
                        return (
                            <MenuItem key={index} value={item} >{item}</MenuItem>
                        )
                    })}
                </Select>
            </div>
        );
    }
}