import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import '../../App.css';

export default function DialogV2(props) {
    return (
        <Dialog maxWidth="sm" className="dialogV2" open={props.open} onClose={props.onClose} >
            {props.title && <DialogTitle style={{ padding: '25px 20px 8px 20px'}} align='center'>
                {props.title}
            </DialogTitle>}
            {props.content && <DialogContent>
                {props.content}
            </DialogContent>}
            {props.actions && <DialogActions style={{ padding: '8px 20px 25px 20px', justifyContent: 'center'}}>
                {props.actions}
            </DialogActions>}
        </Dialog>
    )
}