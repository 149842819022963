import React from 'react';
import { Grid } from '@material-ui/core';
import config from '../../config';

const GlossaryImageGrid = (props) => {
    function getImageUrl(image, folder) {
        if (image.includes('https://' || 'http://')) { return image; }
        
        const environment = (props.env === 'upper') ? config.environments.destinationEnvironment : config.environments.sourceEnvironment;
        let cloudFrontUrl;
        switch (environment) {
            case 'prod':
                cloudFrontUrl = 'https://engage.toyota.com';
                break;
            case 'staging':
                cloudFrontUrl = 'https://staging.engage.toyota.com';
                break;
            case 'test':
                cloudFrontUrl = 'https://test.nonprod.engage.toyota.com';
                break;
            case 'dev':
                cloudFrontUrl = 'https://dev.nonprod.engage.toyota.com';
                break;
        }

        return `${cloudFrontUrl}/static/images/glossary/${folder}/${image}`;
    }

    return (<>
        <Grid className='image-card' container spacing={3} >
            {props.images.map((image, index) => {
                if (!image) return;
                return (
                    <Grid item xs={6} key={`${props.env}/${image}-${index}`} >
                        <div className="image-wrapper">
                            {props.editing && <span className="delete-image-button" id={image} onClick={props.deleteImage} >x</span>}
                            <img src={getImageUrl(image, props.folder)} style={{ maxHeight: '100px', maxWidth: '100px' }} />
                        </div>
                        <div className='caption'>{image}</div>
                    </Grid>
                );
            })}
        </Grid>
    </>)
}

export default GlossaryImageGrid;