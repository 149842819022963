import React from 'react';
import {
    Chip
} from '@material-ui/core';
import {
    Check as CheckIcon,
} from '@material-ui/icons';
import config from '../../config';

function PromotedChip(props) {
    const formattedDate = props.date && Intl.DateTimeFormat("en-us", { month: "2-digit", day: "2-digit", year: "2-digit" }).format(Date.parse(props.date));

    return (
        <Chip
            icon={<CheckIcon style={props.expired ? { color: '#616161' } : { color: '#FFFFFF' }} />}
            label={typeof formattedDate === 'string' ? formattedDate : config.environments.formattedDestinationEnvironment}
            size="small"
            style={props.expired ? { backgroundColor: "#e0e0e0", color: "#616161", width: '100%' } : { backgroundColor: '#7A306C', color: '#FFFFFF', width: '100%' }}
        />
    );
}

export default PromotedChip;
