import React from 'react';

// MATERIAL-UI V4
import {
    Tooltip,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'

// MATERIAL-UI ICONS V4
import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';


function MenuSection(props) {
    return (
        <Tooltip arrow enterDelay={1000} placement='right' title={props.disable ? `You don't have permission to view this page.` : ''} >
            <span>
                <ListItem button disableRipple disabled={props.pathRoot === props.root || props.disable} onClick={() => props.expandCollapse(props.root)}>
                    <ListItemIcon>{props.icon}</ListItemIcon>
                    <ListItemText primary={props.label} />
                    {!props.disable && props.pathRoot !== props.root &&
                        (props.expandedSection === props.root ? <ExpandLessIcon /> : <ExpandMoreIcon />)
                    }
                </ListItem>
            </span>
        </Tooltip>
    );
}

export default MenuSection;
