import React, { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import GlossaryImageGrid from './glossaryImageGrid';
import DragAndDropFileUploader from '../../components/DragAndDropFileUploader';
import {
    Card,
    Grid,
    Tooltip,
    Button,
    CircularProgress,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import {
    Save as SaveIcon,
    Visibility as StagingIcon,
    Delete as ArchiveIcon,
    Close as CloseIcon,
    LibraryAdd as CreateIcon,
} from '@material-ui/icons';
import {
    isValidFileName,
} from '../../utils';
import config from '../../config';
import './glossary.css';


const GlossaryTermEdit = (props) => {
    const [termObject, setTermObject] = useState({
        id: props.term.id || uuidV4(),
        term: props.term.term || "",
        category: props.term.category || "",
        description: props.term.description || "",
        disclaimer: props.term.disclaimer || "",
        notes: props.term.notes || "",
        images: props.term.images || [],
        firstContentName: props.term.relatedContent ? props.term.relatedContent[0]?.name : "",
        firstContentUrl: props.term.relatedContent ? props.term.relatedContent[0]?.url : "",
        secondContentName: props.term.relatedContent ? props.term.relatedContent[1]?.name : "",
        secondContentUrl: props.term.relatedContent ? props.term.relatedContent[1]?.url : "",
        thirdContentName: props.term.relatedContent ? props.term.relatedContent[2]?.name : "",
        thirdContentUrl: props.term.relatedContent ? props.term.relatedContent[2]?.url : "",
        featureAvailability: props.term.featureAvailability || "",
    });
    const [uploadImages, setUploadImages] = useState([]);

    function archiveTerm() {
        props.archiveTerm(termObject.id);
    }

    function handleSaveClick() {
        const term = {
            id: termObject.id,
            stagingId: termObject.id,
            term: termObject.term,
            category: termObject.category,
            description: termObject.description,
            disclaimer: termObject.disclaimer,
            notes: termObject.notes,
            images: termObject.images,
            relatedContent: [
                {
                    name: termObject.firstContentName,
                    url: reformatEngageUrl(termObject.firstContentUrl)
                },
                {
                    name: termObject.secondContentName,
                    url: reformatEngageUrl(termObject.secondContentUrl)
                },
                {
                    name: termObject.thirdContentName,
                    url: reformatEngageUrl(termObject.thirdContentUrl)
                }
            ],
            featureAvailability: reformatEngageUrl(termObject.featureAvailability),
            isArchived: false,
        };

        for (let i = 0; i < uploadImages.length; i++) {
            term.images.push(uploadImages[i].name);
        }

        if (props.createMode) {
            props.createNewTerm(term);
        } else {
            props.saveTerm(term);
        }
    }

    function handleCancelClick() {
        if (props.createMode) props.setCreateMode(false);
        if (!props.createMode) props.discardChanges();
    }

    function handleChange(event) {
        const updatedTerm = termObject;
        updatedTerm[event.target.id] = event.target.value;
        setTermObject({ ...updatedTerm });
    }

    function handleDropdown(event) {
        const updatedTerm = termObject;
        updatedTerm.category = event.target.value;
        setTermObject({ ...updatedTerm });
    }

    function deleteImage(event) {
        setTermObject({
            ...termObject,
            images: termObject.images.filter(i => i !== event.target.id),
        });
    }

    async function addNewImage(event) {
        let files = event;
        for (let item in files) {
            let file = files[item];
            if (isValidFileName(file.name)) {
                setUploadImages([...uploadImages, file]);
                await props.uploadImage(file, termObject.id);
            }
            else {
                showInvalidFileNameDialog();
            }
        }
    }

    function removeNewImage(index) {
        const images = uploadImages;
        images.splice(index, 1);
        setUploadImages(images);
    }

    function showInvalidFileNameDialog() {
        window.alert("One or more of the files you selected are formatted incorrectly. File names should include only letters, numbers, underscores, periods, and dashes. Please remove any spaces or special characters and remember to update the file name reference in the template.");
    }

    function reformatEngageUrl(fullUrl) {
        const engageRegex = /^((http|https):)([/]{2})(engage.toyota.com)/;
        if (!!fullUrl.match(engageRegex)) {
            return fullUrl.replace('https://engage.toyota.com/', '/');
        } else {
            return fullUrl;
        }
    }

    function renderStatus() {
        switch (props.term.status) {
            case 'lower':
                return (<>
                    <StagingIcon />
                    <div>{config.environments.sourceEnvironment.toUpperCase()}</div>
                </>);
            case 'create':
                return (<>
                    <CreateIcon />
                    <div>new term</div>
                </>);
            default: return;
        }
    }

    function renderButtons() {
        if (props.createMode) {
            return (<>
                <Tooltip arrow placement="left" title="Add Term to Staging">
                    <Button
                        startIcon={props.saving ? <CircularProgress size="20px" color="inherit" /> : <SaveIcon />}
                        variant="contained"
                        className="button"
                        color="primary" 
                        onClick={handleSaveClick}
                    > Create </Button>
                </Tooltip>
                <Tooltip arrow placement="left" title="Discard Term">
                    <Button startIcon={<CloseIcon />} variant="contained" className="button" color='inherit' onClick={handleCancelClick} > Cancel </Button>
                </Tooltip>
            </>);
        } else {
            return (<>
                <Tooltip arrow placement="left" title="Save Changes to Staging">
                    <Button
                        startIcon={props.saving ? <CircularProgress size="20px" color="inherit" /> : <SaveIcon />}
                        className="button"
                        variant="contained"
                        color="primary"
                        onClick={handleSaveClick}
                    >
                        Save
                    </Button>
                </Tooltip>
                <Tooltip arrow placement="left" title="Remove Term from Staging">
                    <Button
                        startIcon={props.archiving ? <CircularProgress size="20px" color="inherit" /> : <ArchiveIcon />}
                        className="button"
                        variant="contained"
                        style={{ backgroundColor: "#EB0A1E", color: "#fff" }}
                        onClick={archiveTerm}
                    >
                        Archive
                    </Button>
                </Tooltip>
                <Tooltip arrow placement="left" title="Discard Changes">
                    <Button startIcon={<CloseIcon />} className="button" variant="contained" color="inherit" onClick={handleCancelClick} > Cancel </Button>
                </Tooltip>


            </>);
        }
    }

    return (
        <Card className="glossary-edit card" variant="outlined">
            <Grid container spacing={3}>
                <Grid item md={1} className="column environment">
                    {renderStatus()}
                </Grid>
                <Grid item md={2} className="column info">
                    <TextField
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        className="textfield"
                        fullWidth
                        id="term"
                        label="Term"
                        value={termObject.term}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth variant="outlined" className="select-input">
                        <InputLabel id="category-label">Category</InputLabel>
                        <Select
                            id="category"
                            labelId="category-label"
                            label="category"
                            value={termObject.category}
                            onChange={handleDropdown}
                        >
                            {props.menuCategories.map(category => {
                                return <MenuItem id="category" key={category} value={category}>{category}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <br />
                    {props.term.id && <div className="details"><strong>ID: </strong>{props.term.id}</div>}
                </Grid>
                <Grid item md={4} className="column content">
                    <TextField
                        className="textfield"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="description"
                        label="Description"
                        multiline
                        minRows={4}
                        value={termObject.description}
                        onChange={handleChange}
                    />
                    <br /><br />
                    <TextField
                        className="textfield"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "49%", marginRight: "2%" }}
                        id="firstContentName"
                        label="Related Content Text"
                        value={termObject.firstContentName}
                        onChange={handleChange}
                    />
                    <TextField
                        className="textfield"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "49%" }}
                        id="firstContentUrl"
                        label="Related Content URL"
                        value={termObject.firstContentUrl}
                        onChange={handleChange}
                    />
                    <br /><br />
                    <TextField
                        className="textfield"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "49%", marginRight: "2%" }}
                        id="secondContentName"
                        label="Related Content Text"
                        value={termObject.secondContentName}
                        onChange={handleChange}
                    />
                    <TextField
                        className="textfield"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "49%" }}
                        id="secondContentUrl"
                        label="Related Content URL"
                        value={termObject.secondContentUrl}
                        onChange={handleChange}
                    />
                    <br /><br />
                    <TextField
                        className="textfield"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "49%", marginRight: "2%" }}
                        id="thirdContentName"
                        label="Related Content Text"
                        value={termObject.thirdContentName}
                        onChange={handleChange}
                    />
                    <TextField
                        className="textfield"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        style={{ width: "49%" }}
                        id="thirdContentUrl"
                        label="Related Content URL"
                        value={termObject.thirdContentUrl}
                        onChange={handleChange}
                    />
                    <br /><br />
                    <TextField
                        className="textfield"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="featureAvailability"
                        label="Feature Availability URL"
                        value={termObject.featureAvailability}
                        onChange={handleChange}
                        helperText='Internal URLs should start with "/" (e.g. "/app/getstarted")'
                    />
                    <br /><br />
                    <TextField
                        className="textfield"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="disclaimer"
                        label="Disclaimer Text"
                        multiline
                        minRows={3}
                        value={termObject.disclaimer}
                        onChange={handleChange}
                    />
                    <br /><br />
                    <TextField
                        className="textfield"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="notes"
                        label="Content Manager Notes"
                        multiline
                        minRows={2}
                        value={termObject.notes}
                        onChange={handleChange}
                        helperText='These notes are only ever visible on Mission Control'
                    />
                </Grid>
                <Grid item md={3} className="column images">
                    {termObject.images.length > 0 ?
                        <GlossaryImageGrid
                            env={props.term.status}
                            images={termObject.images}
                            folder={props.term.stagingId}
                            deleteImage={deleteImage}
                            editing={true}
                        />
                        : <></>
                    }
                    <DragAndDropFileUploader
                        description='Drag and drop or click here to upload images'
                        files={uploadImages}
                        addFile={addNewImage}
                        removeFile={removeNewImage}
                    />
                </Grid>
                <Grid item md={2} className="column actions">
                    {renderButtons()}
                </Grid>
            </Grid>
        </Card>
    );
}

export default GlossaryTermEdit;