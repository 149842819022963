import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Paper from '@material-ui/core/Paper'; 

// TODO make this functional! we don't need freakin state here!
export default class ListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            items: props.items,
            listType: props.listType,
            maxHeight: props.maxHeight ? props.maxHeight : 200,
        };
        this.Items = this.Items.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            items: nextProps.items
        }); 
    }

    handleDelete = function(value) {
        this.props.removeListItem(value, this.state.items, this.state.listType);
    }

    Items = function () {
        const items = [];
        let sortedItems = this.state.items.sort();
        for (let i = 0; i < sortedItems.length; i++) {
            items.push(
                <ListItem key={i}>
                    <ListItemText
                      primary={this.state.items[i]}
                    />
                    <ListItemSecondaryAction >
                      <IconButton aria-label="Cancel" onClick={()=> this.handleDelete(this.state.items[i])}>
                        <CancelIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            );
        }

        return items;
    }

    render() {
        return (
            <Paper style={{ width: this.props.width ? this.props.width : "100%", maxHeight: this.state.maxHeight, minHeight: 100, overflow: 'auto' }}>
                <List>
                    <this.Items />
                </List>
            </Paper>
        );
    }
}
