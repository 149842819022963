import React from 'react';
import {
    Container,
    Grid,
} from '@material-ui/core';
import {
    Description as ArticleIcon,
    ViewList as TemplateIcon,
    Notifications as NotificationIcon,
    PhoneIphone as AppIcon,
    Add as AddIcon,
} from '@material-ui/icons';

import QuickActionCard from '../../components/QuickActionCard';
import NotificationActivityCard from './NotificationActivityCard';
import TemplateActivityCard from './TemplateActivityCard';
import ArticleActivityCard from './ArticleActivityCard';
import ScheduledContentCard from './ScheduledContentCard';
import RecentErrorsCard from './RecentErrorsCard';

function HomePageComponent(props) {
    const quickActionCards = [
        { titleIcon: <ArticleIcon />, title: 'Articles', link: '/articles/create', actionIcon: <AddIcon />, actionColor: 'primary', allowAction: props.securityGroup?.articles },
        { titleIcon: <TemplateIcon />, title: 'Templates', link: '/templates/publish', actionIcon: <AddIcon />, actionColor: 'primary', allowAction: props.securityGroup?.templates },
        { titleIcon: <NotificationIcon />, title: 'Notifications', link: '/notifications/create', actionIcon: <AddIcon />, actionColor: 'primary', allowAction: props.securityGroup?.notifications },
    ];

    return (
        <Container style={{ marginTop: '25px' }} >
            <Grid container spacing={3} >
                <Grid className='quick-action-cards' item container spacing={3}>
                    <Grid item container spacing={3} xs={12} sm={6} >
                        {quickActionCards[0] && <Grid item xs={12} md={6} ><QuickActionCard {...quickActionCards[0]} /></Grid>}
                        {quickActionCards[1] && <Grid item xs={12} md={6} ><QuickActionCard {...quickActionCards[1]} /></Grid>}
                    </Grid>
                    <Grid item container spacing={3} xs={12} sm={6} >
                        {quickActionCards[2] && <Grid item xs={12} md={6} ><QuickActionCard {...quickActionCards[2]} /></Grid>}
                        {quickActionCards[3] && <Grid item xs={12} md={6} ><QuickActionCard {...quickActionCards[3]} /></Grid>}
                    </Grid>
                </Grid>
                <Grid className='widgets' item container spacing={3}>
                    <Grid className='activity-widgets' item container xs={12} md={6} spacing={3} alignContent='flex-start' >
                        <Grid item xs={12} >
                            <NotificationActivityCard allowAccess={props.securityGroup?.notifications} />
                        </Grid>
                        <Grid item xs={12} >
                            <TemplateActivityCard allowAccess={props.securityGroup?.pubTempInd} />
                        </Grid>
                        <Grid item xs={12} >
                            <ArticleActivityCard allowAccess={props.securityGroup?.createArtInd} />
                        </Grid>
                    </Grid>
                    <Grid className='status-widgets' item container xs={12} md={6} spacing={3} alignContent='flex-start' >
                        <Grid item xs={12} >
                            <ScheduledContentCard allowAccess={props.securityGroup?.articles || props.securityGroup?.templates || props.securityGroup?.notifications}/>
                        </Grid>
                        <Grid item xs={12} >
                            <RecentErrorsCard />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default HomePageComponent;