import React from 'react';
import Dropzone from 'react-dropzone';
import PDF_ICON from '../../assets/pdf-icon.png';
import DOCUMENT_ICON from '../../assets/file-icon.png';
import IMAGE_ICON from '../../assets/image-icon.png';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';


export default class DragAndDropFileUploader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            files: this.props.files,
            loading: false,
            filesToUpload: 0
        };
        this.beginLoading = this.beginLoading.bind(this);
        this.fileElement = this.fileElement.bind(this);
    }

    beginLoading(filesUploading) {
        this.setState({
            loading: true,
            filesToUpload: this.state.filesToUpload + filesUploading.length
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.files !== this.props.files) {
            if (this.state.filesToUpload.length === this.state.files.length) {
                this.setState({
                    loading: false
                })
            }
            this.setState({
                files: this.props.files,
            })
        }
    }

    fileElement(file, index) {
        let fileIcon = this.getFileIcon(file.name);

        return (
            <li className="file-list-item" key={file.name}>
                <img className="icon" alt="" src={fileIcon} />
                <div className="file-item-name">{file.name} <span className='plain-text'>- {Math.round(file.size / 1000)} KB</span></div>
                <div className="remove-file-button">
                    <IconButton aria-label="Cancel" onClick={() => this.removeFileFromList(index)}>
                        <CancelIcon />
                    </IconButton>
                </div>
            </li>
        )
    }

    removeFileFromList(index) {
        this.setState({
            filesToUpload: (this.state.filesToUpload - 1)
        });
        this.props.removeFile(index)
    }


    getFileIcon(fileName) {
        if (fileName.match(/.pdf$/g)) {
            return PDF_ICON;
        }
        else if (fileName.match(/(\.jpg|\.png|\.gif|\.JPEG)$/gi)) {
            return IMAGE_ICON;
        }
        else {
            return DOCUMENT_ICON;
        }
    }

    render() {
        return (
            <div>
                <Dropzone className="dropzone" onDrop={this.props.addFile} onDropAccepted={this.beginLoading} activeClassName="">
                    <p>{this.props.description}</p>
                </Dropzone>
                {this.state.loading && ((this.state.files.length) !== this.state.filesToUpload) ?
                    <div>
                        <div style={{ marginLeft: "20%", marginRight: "20%", marginTop: "20px" }}>
                        </div>
                    </div>
                    : null}
                <section>
                    <aside>
                        {this.state.files.length > 0 ?
                            <div>
                                <h1>Attached Files (.jpg, .png, and .pdf):</h1>
                                <div className="Line-Break"></div>
                                <div className="Files-List" style={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}>
                                    <Paper elevation={1}>
                                        <ul style={{ listStyle: "none", textAlign: "left", "width": "100%", paddingLeft: "0px" }}>
                                            {this.state.files.map((f, index) => this.fileElement(f, index))}
                                        </ul>
                                    </Paper>
                                </div>
                            </div>
                            : null}
                    </aside>
                </section>
            </div>
        );
    }
}